<template>
  <div class="convient" id="convenient_reliable" ref="convient">
    <div class="main_container">
      <h1 class="convenient_reliable_title">Convenient - Reliable – Safe</h1>
      <div class="convenient_reliable">
        <figure
          class="counerUp_wrapper chart counter"
        >
          <figcaption>
            <span class="convient__counter" id="crypto_counter"></span>
          </figcaption>
          <svg
            class="img_count"
            width="231"
            height="229"
            viewBox="0 0 231 229"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            transform="rotate(0, 0, 0)"
          >
            <mask id="path-1-inside-1_62_1107" fill="white">
              <path
                d="M92.0483 228.594C63.9827 222.774 39.0837 206.727 22.1911 183.571C5.29846 160.416 -2.38089 131.806 0.645742 103.304C3.67237 74.8014 17.1887 48.4426 38.5676 29.351C59.9464 10.2594 87.6602 -0.201087 116.322 0.00292058C144.984 0.206928 172.546 11.0608 193.651 30.4548C214.756 49.8489 227.896 76.3974 230.516 104.94C233.137 133.482 225.051 161.98 207.83 184.893C190.61 207.805 165.485 223.497 137.339 228.916L135.457 219.138C161.175 214.185 184.134 199.847 199.87 178.91C215.606 157.972 222.994 131.932 220.6 105.85C218.205 79.7687 206.198 55.5092 186.913 37.7873C167.628 20.0654 142.442 10.1473 116.251 9.96085C90.0605 9.77443 64.7361 19.333 45.2005 36.7786C25.6649 54.2242 13.3139 78.3104 10.5482 104.355C7.78256 130.4 14.7998 156.543 30.236 177.702C45.6722 198.862 68.4244 213.525 94.0702 218.843L92.0483 228.594Z"
              />
            </mask>
            <path
              class="outer"
              d="M92.0483 228.594C63.9827 222.774 39.0837 206.727 22.1911 183.571C5.29846 160.416 -2.38089 131.806 0.645742 103.304C3.67237 74.8014 17.1887 48.4426 38.5676 29.351C59.9464 10.2594 87.6602 -0.201087 116.322 0.00292058C144.984 0.206928 172.546 11.0608 193.651 30.4548C214.756 49.8489 227.896 76.3974 230.516 104.94C233.137 133.482 225.051 161.98 207.83 184.893C190.61 207.805 165.485 223.497 137.339 228.916L135.457 219.138C161.175 214.185 184.134 199.847 199.87 178.91C215.606 157.972 222.994 131.932 220.6 105.85C218.205 79.7687 206.198 55.5092 186.913 37.7873C167.628 20.0654 142.442 10.1473 116.251 9.96085C90.0605 9.77443 64.7361 19.333 45.2005 36.7786C25.6649 54.2242 13.3139 78.3104 10.5482 104.355C7.78256 130.4 14.7998 156.543 30.236 177.702C45.6722 198.862 68.4244 213.525 94.0702 218.843L92.0483 228.594Z"
              stroke="url(#paint0_linear_62_1107)"
              stroke-width="10"
              stroke-linejoin="round"
              mask="url(#path-1-inside-1_62_1107)"
            />
            <defs>
              <linearGradient
                class="outer"
                id="paint0_linear_62_1107"
                x1="140.5"
                y1="207.5"
                x2="96"
                y2="224"
                gradientUnits="userSpaceOnUse"
              >
                <stop class="outer" stop-color="#02FB4B" />
                <stop
                  class="outer"
                  offset="1"
                  stop-color="#03FB4C"
                  stop-opacity="0.28"
                />
              </linearGradient>
            </defs>
          </svg>
          <div class="convenient_reliable_card">
            <p>
              cryptocurrencies for Purchase
            </p>
            <router-link class="count_link" to="/"
              >Buy now<img src="/images/footer/next_icon.svg"
            /></router-link>
          </div>
        </figure>
        <figure
          class="counerUp_wrapper chart counter"
        >
          <figcaption>
            <span class="convient__counter" id="customer_counter"></span>
          </figcaption>
          <svg
            class="img_count"
            width="231"
            height="229"
            viewBox="0 0 231 229"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            transform="rotate(0, 0, 0)"
          >
            <mask id="path-1-inside-1_62_1107" fill="white">
              <path
                d="M92.0483 228.594C63.9827 222.774 39.0837 206.727 22.1911 183.571C5.29846 160.416 -2.38089 131.806 0.645742 103.304C3.67237 74.8014 17.1887 48.4426 38.5676 29.351C59.9464 10.2594 87.6602 -0.201087 116.322 0.00292058C144.984 0.206928 172.546 11.0608 193.651 30.4548C214.756 49.8489 227.896 76.3974 230.516 104.94C233.137 133.482 225.051 161.98 207.83 184.893C190.61 207.805 165.485 223.497 137.339 228.916L135.457 219.138C161.175 214.185 184.134 199.847 199.87 178.91C215.606 157.972 222.994 131.932 220.6 105.85C218.205 79.7687 206.198 55.5092 186.913 37.7873C167.628 20.0654 142.442 10.1473 116.251 9.96085C90.0605 9.77443 64.7361 19.333 45.2005 36.7786C25.6649 54.2242 13.3139 78.3104 10.5482 104.355C7.78256 130.4 14.7998 156.543 30.236 177.702C45.6722 198.862 68.4244 213.525 94.0702 218.843L92.0483 228.594Z"
              />
            </mask>
            <path
              class="outer"
              d="M92.0483 228.594C63.9827 222.774 39.0837 206.727 22.1911 183.571C5.29846 160.416 -2.38089 131.806 0.645742 103.304C3.67237 74.8014 17.1887 48.4426 38.5676 29.351C59.9464 10.2594 87.6602 -0.201087 116.322 0.00292058C144.984 0.206928 172.546 11.0608 193.651 30.4548C214.756 49.8489 227.896 76.3974 230.516 104.94C233.137 133.482 225.051 161.98 207.83 184.893C190.61 207.805 165.485 223.497 137.339 228.916L135.457 219.138C161.175 214.185 184.134 199.847 199.87 178.91C215.606 157.972 222.994 131.932 220.6 105.85C218.205 79.7687 206.198 55.5092 186.913 37.7873C167.628 20.0654 142.442 10.1473 116.251 9.96085C90.0605 9.77443 64.7361 19.333 45.2005 36.7786C25.6649 54.2242 13.3139 78.3104 10.5482 104.355C7.78256 130.4 14.7998 156.543 30.236 177.702C45.6722 198.862 68.4244 213.525 94.0702 218.843L92.0483 228.594Z"
              stroke="url(#paint0_linear_62_1107)"
              stroke-width="10"
              stroke-linejoin="round"
              mask="url(#path-1-inside-1_62_1107)"
            />
            <defs>
              <linearGradient
                class="outer"
                id="paint0_linear_62_1107"
                x1="140.5"
                y1="207.5"
                x2="96"
                y2="224"
                gradientUnits="userSpaceOnUse"
              >
                <stop class="outer" stop-color="#02FB4B" />
                <stop
                  class="outer"
                  offset="1"
                  stop-color="#03FB4C"
                  stop-opacity="0.28"
                />
              </linearGradient>
            </defs>
          </svg>
          <div class="convenient_reliable_card">
            <p>
              сustomers trust us
            </p>
            <router-link class="count_link" to="/"
              >Sign Up<img src="/images/footer/next_icon.svg"
            /></router-link>
          </div>
        </figure>
        <figure
          class="counerUp_wrapper chart counter"
        >
          <figcaption>
            <span class="convient__counter" id="exchanges_counter"></span>
          </figcaption>
          <svg
            class="img_count"
            width="231"
            height="229"
            viewBox="0 0 231 229"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            transform="rotate(0, 0, 0)"
          >
            <mask id="path-1-inside-1_62_1107" fill="white">
              <path
                d="M92.0483 228.594C63.9827 222.774 39.0837 206.727 22.1911 183.571C5.29846 160.416 -2.38089 131.806 0.645742 103.304C3.67237 74.8014 17.1887 48.4426 38.5676 29.351C59.9464 10.2594 87.6602 -0.201087 116.322 0.00292058C144.984 0.206928 172.546 11.0608 193.651 30.4548C214.756 49.8489 227.896 76.3974 230.516 104.94C233.137 133.482 225.051 161.98 207.83 184.893C190.61 207.805 165.485 223.497 137.339 228.916L135.457 219.138C161.175 214.185 184.134 199.847 199.87 178.91C215.606 157.972 222.994 131.932 220.6 105.85C218.205 79.7687 206.198 55.5092 186.913 37.7873C167.628 20.0654 142.442 10.1473 116.251 9.96085C90.0605 9.77443 64.7361 19.333 45.2005 36.7786C25.6649 54.2242 13.3139 78.3104 10.5482 104.355C7.78256 130.4 14.7998 156.543 30.236 177.702C45.6722 198.862 68.4244 213.525 94.0702 218.843L92.0483 228.594Z"
              />
            </mask>
            <path
              class="outer"
              d="M92.0483 228.594C63.9827 222.774 39.0837 206.727 22.1911 183.571C5.29846 160.416 -2.38089 131.806 0.645742 103.304C3.67237 74.8014 17.1887 48.4426 38.5676 29.351C59.9464 10.2594 87.6602 -0.201087 116.322 0.00292058C144.984 0.206928 172.546 11.0608 193.651 30.4548C214.756 49.8489 227.896 76.3974 230.516 104.94C233.137 133.482 225.051 161.98 207.83 184.893C190.61 207.805 165.485 223.497 137.339 228.916L135.457 219.138C161.175 214.185 184.134 199.847 199.87 178.91C215.606 157.972 222.994 131.932 220.6 105.85C218.205 79.7687 206.198 55.5092 186.913 37.7873C167.628 20.0654 142.442 10.1473 116.251 9.96085C90.0605 9.77443 64.7361 19.333 45.2005 36.7786C25.6649 54.2242 13.3139 78.3104 10.5482 104.355C7.78256 130.4 14.7998 156.543 30.236 177.702C45.6722 198.862 68.4244 213.525 94.0702 218.843L92.0483 228.594Z"
              stroke="url(#paint0_linear_62_1107)"
              stroke-width="10"
              stroke-linejoin="round"
              mask="url(#path-1-inside-1_62_1107)"
            />
            <defs>
              <linearGradient
                class="outer"
                id="paint0_linear_62_1107"
                x1="140.5"
                y1="207.5"
                x2="96"
                y2="224"
                gradientUnits="userSpaceOnUse"
              >
                <stop class="outer" stop-color="#02FB4B" />
                <stop
                  class="outer"
                  offset="1"
                  stop-color="#03FB4C"
                  stop-opacity="0.28"
                />
              </linearGradient>
            </defs>
          </svg>
          <div class="convenient_reliable_card">
            <p>
              exchanges per day
            </p>
            <router-link class="count_link" to="/"
              >Exchange<img src="/images/footer/next_icon.svg"
            /></router-link>
          </div>
        </figure>
      </div>
    </div>
  </div>
</template>

<script>
import ICountUp from "vue-countup-v2";
import { CountUp } from "countup.js";
export default {
  name: "CounterUp",
  components: {
    ICountUp,
  },
  data() {
    return {
      countOne: [
        {
          textDescription: "криптовалют для обмена",
          delay: 1000,
          time: 1200,
          endVal: 100,
          options: {
            useEasing: true,
            useGrouping: true,
            separator: "",
            decimal: "",
            prefix: "",
            suffix: "",
            enableScrollSpy: true
          },
        },
        {
          textDescription: "клиентов нам доверяют",
          delay: 1000,
          endVal: 4000,
          time: 1200,
          plus: "+",
          options: {
            useEasing: true,
            useGrouping: true,
            separator: "",
            decimal: "",
            prefix: "",
            suffix: "",
            enableScrollSpy: true
          },
        },
        {
          textDescription: "проводимых обменов в сутки",
          delay: 1000,
          endVal: 200,
          time: 1200,
          options: {
            useEasing: true,
            useGrouping: true,
            separator: "",
            decimal: "",
            prefix: "",
            suffix: "",
            enableScrollSpy: true
          },
        },
      ],
      opts: {
        duration: 1.5,
        useEasing: true,
        useGrouping: true,
        separator: "",
        decimal: "",
        prefix: "+",
        suffix: "",
        enableScrollSpy: true
      },
      position: null
    };
  },
  methods: {
    count() {
      const crypto_count = new CountUp ('crypto_counter', 10, this.opts)
      const customer_counter = new CountUp ('customer_counter', 500, this.opts)
      const exchanges_counter = new CountUp('exchanges_counter', 50, this.opts)
      crypto_count.start()
      customer_counter.start()
      exchanges_counter.start()

    },   
  },
  mounted() {
    document.addEventListener('DOMSubtreeModified', (e) => {
      if (!e.target.classList.contains('convient__counter')) return
      const value = parseInt(e.target.innerText)
      if (isNaN(value)) return
      if (value > 0) {
        e.target.closest('.chart').classList.add('active')
      }else if (value === 0) {
        e.target.closest('.chart').classList.remove('active')
      }
    })
    this.count()
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#convenient_reliable {
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  .main_container {
    max-width: 983px;
    margin: 0 auto;
    .convenient_reliable_title {
      font-family: "Sofia Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 35px;
      line-height: 42px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 70px;
    }
    .convenient_reliable {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: 140px;
      .counerUp_wrapper {
        figcaption {
          font-family: "Montserrat", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 50px;
          line-height: 61px;
          text-align: center;
          color: #00fb49;
          position: absolute;
          left: 50%;
          top: 29%;
          transform: translate(-50%, -50%);
          z-index: 5;
        }
        .img_count {
          // --angle: 0deg;
          // border: 5px solid;
          // border-image: linear-gradient(var(--angle), #12c2e9, #c471ed, #f64f59)
          //   1;
          // height: 230.99998474121114px;
          // width: 231.00001525878926px;
          border-radius: 50%;
          position: relative;
          text-align: center;
          margin: 0 auto;
          display: block;
          z-index: 2;
          // animation: 5s rotate linear infinite;
        }
      }
    }
    .convenient_reliable_card {
      margin: 0 auto;
      p {
        font-family: "Sofia Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
        color: #ffffff;
        max-width: 172px;
        margin: 0 auto;
        margin-top: 24px;
      }
      .count_link {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        text-align: center;
        margin: 0 auto;
        margin-top: 60px;
        display: block;
        background: linear-gradient(
          270deg,
          rgba(18, 170, 2, 0.16) 0%,
          rgba(126, 196, 146, 0.16) 100%
        );
        border-radius: 8px;
        transition: 0.5s;
        padding: 10px 13px;
        text-decoration: none;
        max-width: 170px;
        white-space: nowrap;
        img {
          margin-left: 13px;
        }
        &:hover {
          background: linear-gradient(270deg, #10aa02 0%, #096200 100%);
        }
      }
    }
  }
}

/* Container styles */
body {
  background-color: #fff;
  color: #333;
  font-family: "Lato";
}

.container {
  padding: 50px 0;
  text-align: center;
}

.chart {
  position: relative;
  display: inline-block;
  color: #999;
  font-size: 20px;
  text-align: center;
}

/* END Container styles */

/* Colors for the circles and positions for the graphics */
figcaption {
  top: 50px;
  left: 45px;
}

figcaption + svg .outer {
  // stroke: #02fb4b;
}

.css {
  top: 55px;
  left: 48px;
}

.css + svg .outer {
  stroke: #02fb4b;
}

.javascript {
  max-width: 90px;
  max-height: 90px;
  top: 45px;
  left: 45px;
}

.javascript + svg .outer {
  stroke: #f0e040;
}

.node {
  width: 200px;
  height: 200px;
  top: 45px;
  left: 45px;
}

.node + svg .outer {
  stroke: #83cd29;
}

.chart svg {
  // position: absolute;
  // top: 85px;
  left: 0;
}

.outer {
  fill: transparent;
  stroke-width: 20;
  stroke-dasharray: 785;
  stroke-dashoffset: 500 !important;
  transition: all 2s;
  -webkit-animation-play-state: running;

  /* firefox bug fix - won't rotate at 90deg angles */
  -moz-transform: rotate(-89deg) translateX(-190px);
}

.chart.active .outer {
  stroke-dashoffset: 0 !important;
  -webkit-animation-play-state: infinite;
  animation-iteration-count: infinite !important;
}
/* END Circle colors and graphic positions */

/* Set the initial values for the animation */
.chart[data-percent="100"] .outer {
  stroke-dashoffset: 10000;
  -webkit-animation: show100 2s;
  animation: show100 2s;
  animation-iteration-count: infinite !important;
}

.chart[data-percent="75"] .outer {
  stroke-dashoffset: 1000;
  -webkit-animation: show75 2s;
  animation: show75 2s;
  animation-iteration-count: infinite !important;
}

.chart[data-percent="50"] .outer {
  stroke-dashoffset: 1000;
  -webkit-animation: show50 2s;
  animation: show50 2s;
  animation-iteration-count: infinite !important;
}

.chart[data-percent="25"] .outer {
  stroke-dashoffset: 1000;
  -webkit-animation: show25 2s;
  animation: show25 2s;
  animation-iteration-count: infinite !important;
}
/* END set initial animation values */

@keyframes show100 {
  from {
    stroke-dashoffset: 537;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes show75 {
  from {
    stroke-dashoffset: 537;
  }

  to {
    stroke-dashoffset: 124;
  }
}

@keyframes show50 {
  from {
    stroke-dashoffset: 537;
  }

  to {
    stroke-dashoffset: 267;
  }
}

@keyframes show25 {
  from {
    stroke-dashoffset: 537;
  }

  to {
    stroke-dashoffset: 401;
  }
}
@media (max-width: 992px) {
  #convenient_reliable {
    padding: 80px 0 50px;
  }
}
@media (max-width: 860px) {
  #convenient_reliable {
    .main_container {
      .convenient_reliable {
        grid-template-columns: repeat(2, 1fr);
        gap: 25px;
        .counerUp_wrapper {
          &:last-child {
            justify-content: center;
            align-items: center;
            transform: translateX(50%);
          }
        }
      }
    }
  }
}
@media (max-width: 525px) {
  #convenient_reliable {
    .main_container {
      .convenient_reliable_title {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 30px;
      }
      .convenient_reliable {
        grid-template-columns: repeat(1, 1fr);
        gap: 25px;
        margin-bottom: 30px;
        .counerUp_wrapper {
          &:last-child {
            justify-content: center;
            align-items: center;
            transform: translateX(0);
          }
        }
      }
    }
  }
}
</style>
