<template>
  <div class="homeComponent" id="exchange">
    <Transition>
      <Messangers />
    </Transition>
    <div class="home_position_images">
      <span class="circel_bg circel" data-speed="2"></span>
      <span class="circel_bg_red circel" data-speed="-5"></span>
      <picture>
        <source
          :srcset="require('@/assets/images/home/globus-min.webp')"
          type="image/webp"
        />
        <source
          :srcset="require('@/assets/images/home/globus-min.png')"
          type="image/png"
        />
        <img
          class="place_img"
          :src="require('@/assets/images/home/globus-min.png')"
          alt="place_img"
        />
      </picture>
      <picture>
        <source
          :srcset="require('@/assets/images/home/home-right-bg-min.webp')"
          type="image/webp"
        />
        <source
          :srcset="require('@/assets/images/home/home_right_bg-min.png')"
          type="image/png"
        />
        <img
          class="home_right_bg"
          :src="require('@/assets/images/home/home_right_bg-min.png')"
          alt="home_right_bg"
        />
      </picture>

      <picture>
        <source
          :srcset="require('@/assets/images/home/green-bg-min.webp')"
          type="image/webp"
        />
        <source
          :srcset="require('@/assets/images/home/green_bg-min.png')"
          type="image/png"
        />
        <img
          class="green_bg"
          :src="require('@/assets/images/home/green_bg-min.png')"
          alt="green_bg"
        />
      </picture>

      <picture>
        <source
          :srcset="require('@/assets/images/home/red-bg-min.webp')"
          tyep="image/webp"
        />
        <source
          :srcset="require('@/assets/images/home/red_bg-min.png')"
          tyep="image/png"
        />
        <img
          class="red_bg"
          :src="require('@/assets/images/home/red_bg-min.png')"
          alt="red_bg"
        />
      </picture>

      <a href="#" class="top_start">
        <svg
          width="62"
          height="62"
          viewBox="0 0 62 62"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect opacity="0.1" width="62" height="62" rx="10" fill="#D9D9D9" />
          <path
            d="M36.7141 30.285L31.7141 25.285C31.619 25.194 31.5068 25.1226 31.3841 25.075C31.1406 24.975 30.8676 24.975 30.6241 25.075C30.5013 25.1226 30.3892 25.194 30.2941 25.285L25.2941 30.285C25.2009 30.3783 25.1269 30.4889 25.0764 30.6108C25.026 30.7326 25 30.8632 25 30.995C25 31.2613 25.1058 31.5167 25.2941 31.705C25.4824 31.8933 25.7378 31.9991 26.0041 31.9991C26.2704 31.9991 26.5258 31.8933 26.7141 31.705L30.0041 28.405V35.995C30.0041 36.2602 30.1094 36.5146 30.297 36.7021C30.4845 36.8897 30.7389 36.995 31.0041 36.995C31.2693 36.995 31.5237 36.8897 31.7112 36.7021C31.8987 36.5146 32.0041 36.2602 32.0041 35.995V28.405L35.2941 31.705C35.3871 31.7987 35.4977 31.8731 35.6195 31.9239C35.7414 31.9747 35.8721 32.0008 36.0041 32.0008C36.1361 32.0008 36.2668 31.9747 36.3887 31.9239C36.5105 31.8731 36.6211 31.7987 36.7141 31.705C36.8078 31.6121 36.8822 31.5014 36.933 31.3796C36.9838 31.2577 37.0099 31.127 37.0099 30.995C37.0099 30.863 36.9838 30.7323 36.933 30.6104C36.8822 30.4886 36.8078 30.378 36.7141 30.285Z"
            fill="white"
          />
        </svg>
      </a>
    </div>
    <div class="homeComponent_in">
      <div class="homeComponent_in_wrapper">
        <div class="homeComponent_in_left">
          <div class="home_logo">
            <img src="/images/Logo.svg" alt="logo" />
            FIXONE <br />
            GLOBAL TRADING
            <input type="range" id="power" />
          </div>
          <h1 class="home_title">
            <span class="best">Best </span> <br />
            <span class="title_in">exchange service </span>
          </h1>
          <div class="for_partners">for our partners</div>
          <picture>
            <source
              :src="require('@/assets/images/home/vector-min.webp')"
              type="image/webp"
            />
            <source
              :src="require('@/assets/images/home/Vector-min.png')"
              type="image/png"
            />
            <img
              class="vector_img"
              :src="require('@/assets/images/home/Vector-min.png')"
              alt="Vector"
            />
          </picture>
        </div>
        <div class="homeComponent_in_right">
          <div class="red_strelka">
            <span class="red_strelka_in">
              <img
                class="red_strelka_big"
                height="455.47px"
                src="/images/home/red_strelka.svg"
                alt="green_bg"
              />
              <img
                class="red_strelka_small_375 red_strelka_small"
                height="375.01px"
                src="/images/home/red_strelka.svg"
                alt="green_bg"
              />
              <img
                class="red_strelka_small_274 red_strelka_small"
                height="274.14px"
                src="/images/home/red_strelka.svg"
                alt="green_bg"
              />
            </span>
          </div>
          <div class="green_strelka">
            <span class="green_strelka_in">
              <img
                class="green_strelka_big"
                height="455.47px"
                src="/images/home/green_strelka.svg"
                alt="green_bg"
              />
              <img
                class="green_strelka_small_375 green_strelka_small"
                height="375.01px"
                src="/images/home/green_strelka.svg"
                alt="green_bg"
              />
              <img
                class="green_strelka_small_274 green_strelka_small"
                height="274.14px"
                src="/images/home/green_strelka.svg"
                alt="green_bg"
              />
            </span>
          </div>
        </div>
      </div>
      <Calculator />
    </div>
  </div>
</template>
<script>
import FixSelect from './common/FixSelect.vue'
import Messangers from './Messangers.vue'
import Calculator from './Calculator.vue'

export default {
  name: 'homeComponent',
  components: {
    Messangers,
    FixSelect,
    Calculator
  },
  data() {
    return {
      bitcoin: false,
      dollor: false,
      givingAway: null
    }
  },
  methods: {
    selectMenuBetcoin() {
      this.bitcoin = !this.bitcoin
      this.dollor = false
    },
    selectMenuDollor() {
      this.dollor = !this.dollor
      this.bitcoin = false
    }
  },
  mounted() {
    document.addEventListener('mousemove', function (e) {
      this.querySelectorAll('.circel').forEach(circel => {
        const speed = circel.getAttribute('data-speed')
        const x = (window.innerWidth - e.pageX * speed) / 100
        const y = (window.innerHeight - e.pageY * speed) / 100
        circel.style.transform = `translateX(${x}px) translateY(${y}px)`
      })
    })
  }
}
</script>
<style lang="scss" scoped>
.homeComponent {
  position: relative;
  padding: 0 20px;
  padding-bottom: 100px;
  .messengers_icons {
    position: fixed;
    left: 34px;
    bottom: 0;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: 1s;
    z-index: 20;
    a {
      display: block;
      margin-bottom: 50px;
      svg {
        path {
          transition: 0.7s;
        }
      }
      &:hover {
        svg {
          path {
            fill: #00fb49;
          }
        }
      }
    }
  }
  .home_position_images {
    .circel_bg {
      display: block;
      position: absolute;
      top: 387px;
      right: 117px;
      background: #57f409;
      border-radius: 50%;
      width: 28px;
      height: 28px;
      z-index: 3;
    }
    .circel_bg_red {
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      top: 664px;
      left: 1068px;
      z-index: 3;
      background: #f40909;
    }
    .place_img {
      position: absolute;
      top: -112px;
      right: -248px;
    }
    .home_right_bg {
      position: absolute;
      top: -410px;
      right: -1024px;
      z-index: 1;
    }
    .green_bg {
      position: absolute;
      top: -350px;
      right: -494px;
      z-index: 2;
    }
    .red_bg {
      position: absolute;
      bottom: -450px;
      left: -550px;
    }
    .top_start {
      width: auto;
      position: fixed;
      bottom: 38px;
      right: 38px;
      z-index: 50;
      cursor: pointer;

      path {
        transition: fill 0.3s;
      }

      path {
        fill: #00fb49;
      }
    }
  }
  .homeComponent_in {
    max-width: 1345px;
    margin: 0 auto;
    position: relative;
    z-index: 10;

    .homeComponent_in_wrapper {
      display: flex;
      .homeComponent_in_left {
        min-width: 564px;
        padding-top: 225px;
        margin-bottom: 211px;
        position: relative;
        .vector_img {
          position: absolute;
          bottom: -320px;
          left: 23px;
        }
        .home_logo {
          display: flex;
          align-items: end;
          position: relative;
          z-index: 1;
          img {
            margin-right: 16px;
          }
          input {
            -webkit-appearance: none;
            width: 210px;
            margin-bottom: 10px;
            margin-left: 26px;
            background-image: linear-gradient(#ff4500, #ff4500);
          }
          input[type='range']::-webkit-slider-thumb {
            -webkit-appearance: none;
          }
          input[type='range']::-webkit-slider-runnable-track {
            -webkit-appearance: none;
            height: 1px;
            background-color: #ffffff !important;
          }
          input[type='range']::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 16px;
            height: 16px;
            transform: translateY(-50%);
            border-radius: 50%;
            background: #57f409;
            cursor: ew-resize;
            box-shadow: 0 0 2px 0 #555;
            transition: background 0.3s ease-in-out;
            z-index: 10;
          }
          font-family: 'Montserrat', sans-serif;
          font-style: normal;
          font-weight: 300;
          font-size: 17px;
          line-height: 21px;
          letter-spacing: 0.295em;

          color: #ffffff;
        }
        .home_title {
          max-width: 564px;
          height: 221px;
          font-family: 'Sofia Sans', sans-serif;
          line-height: 75.95px;
          position: relative;
          z-index: 1;
          .best {
            display: inline-block;
            font-family: 'Sofia Sans', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 126px;
            color: #ffffff;
            margin-top: 55px;
          }
          .title_in {
            font-family: 'Sofia Sans', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 70px;
            color: #ffffff;
          }
        }
        .for_partners {
          display: inline-block;
          font-family: 'Montserrat', sans-serif;
          font-style: normal;
          font-weight: 300;
          font-size: 30px;
          line-height: 142%;
          color: #ffffff;
          background: #12aa02;
          border-radius: 10px;
          padding: 0 7px 0 13px;
          margin-top: 25px;
          position: relative;
          z-index: 1;
        }
      }
      .homeComponent_in_right {
        width: 100%;
        display: flex;
        justify-content: end;
        padding-right: 120px;
        .red_strelka {
          height: auto;
          display: flex;
          align-items: end;
          .red_strelka_in {
            position: relative;
            bottom: -100px;
          }
          .red_strelka_big {
            position: relative;
            z-index: 3;
            animation: sterkaBig 4s ease infinite;
          }
          .red_strelka_small {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: 1;
            opacity: 0;
            animation: sterka 4s ease infinite;
          }
          .red_strelka_small_375 {
            animation: sterka_375 4s ease infinite;
          }
          @keyframes sterkaBig {
            0% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-40px);
            }
            100% {
              transform: translateY(0);
            }
          }
          @keyframes sterka {
            0% {
              transform: translateY(0);
              opacity: 0;
            }
            50% {
              transform: translateY(-160px);
              opacity: 1;
            }
            100% {
              transform: translateY(0);
              opacity: 0;
            }
          }
          @keyframes sterka_375 {
            0% {
              transform: translateY(0);
              opacity: 0;
            }
            50% {
              transform: translateY(-100px);
              opacity: 1;
            }
            100% {
              transform: translateY(0);
              opacity: 0;
            }
          }
        }
        .green_strelka {
          height: auto;
          display: flex;
          align-items: start;
          margin-top: -11.3px;
          .green_strelka_in {
            position: relative;
          }
          .green_strelka_big {
            position: relative;
            z-index: 3;
            animation: sterkagreenBig 4s ease infinite;
          }
          .green_strelka_small {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: 1;
            opacity: 0;
            animation: sterkagreen 4s ease infinite;
          }
          .green_strelka_small_375 {
            animation: sterkagreen_375 4s ease infinite;
          }
          @keyframes sterkagreenBig {
            0% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(40px);
            }
            100% {
              transform: translateY(0);
            }
          }
          @keyframes sterkagreen {
            0% {
              transform: translateY(0);
              opacity: 0;
            }
            50% {
              transform: translateY(160px);
              opacity: 1;
            }
            100% {
              transform: translateY(0);
              opacity: 0;
            }
          }
          @keyframes sterkagreen_375 {
            0% {
              transform: translateY(0);
              opacity: 0;
            }
            50% {
              transform: translateY(100px);
              opacity: 1;
            }
            100% {
              transform: translateY(0);
              opacity: 0;
            }
          }
        }
      }
    }
  }
  
}

@media (max-width: 1200px) {
  .homeComponent {
    .home_position_images {
      .home_right_bg {
        top: -107px;
        right: -185px;
        height: 1000px;
      }
      .place_img {
        top: -43px;
        right: -180px;
        height: 1000px;
      }
    }
    .homeComponent_in {
      .homeComponent_in_wrapper {
        .homeComponent_in_right {
          padding-right: 0;
        }
      }
    }
    
  }
}
@media (max-width: 992px) {
  .homeComponent {
    .home_position_images {
      .home_right_bg {
        top: -107px;
        right: -185px;
        height: 1000px;
      }
      .place_img {
        top: -43px;
        right: -180px;
        height: 1000px;
      }
    }
    .homeComponent_in {
      .homeComponent_in_wrapper {
        flex-direction: column;
        .homeComponent_in_left {
          margin-bottom: 175px;
          padding-top: 170px;
          .vector_img {
            height: 300px;
            bottom: -200px;
          }
        }
        .homeComponent_in_right {
          justify-content: center;
          margin-top: -100px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .homeComponent {
    .messengers_icons {
      left: 10px;
    }
    .home_position_images {
      .top_start {
        right: 20px;
        bottom: 60px;
        svg {
          height: 42px;
          width: 42px;
        }
      }
      .circel_bg {
        width: 15px;
        height: 15px;
      }
      .home_right_bg {
        top: -57px;
        right: -105px;
        height: 650px;
      }
      .place_img {
        top: -43px;
        right: -180px;
        height: 650px;
      }
      .green_bg {
        top: -188px;
        right: -294px;
        height: 600px;
      }
    }
    .homeComponent_in {
      .homeComponent_in_wrapper {
        .homeComponent_in_left {
          .home_logo {
            font-size: 12px;
            line-height: 18px;
            flex-wrap: wrap;
            input {
              margin-right: 200px;
              max-width: 300px;
              margin-top: 20px;
              margin-left: 0;
            }
          }
          .home_title {
            height: auto;
            line-height: 47.95px;
            .best {
              font-size: 80px;
              margin-top: 40px;
            }
            .title_in {
              font-size: 38px;
            }
          }
          .vector_img {
            height: 200px;
            bottom: -122px;
          }
          .for_partners {
            font-size: 16px;
            margin-top: 15px;
          }
        }
        .homeComponent_in_right {
          .red_strelka {
            .red_strelka_big {
              height: 300px;
            }
            .red_strelka_small {
              height: 200px;
            }
            .red_strelka_small_375 {
              height: 250px;
            }
          }
          @keyframes sterkaBig {
            0% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-30px);
            }
            100% {
              transform: translateY(0);
            }
          }
          @keyframes sterka {
            0% {
              transform: translateY(0);
              opacity: 0;
            }
            50% {
              transform: translateY(-120px);
              opacity: 1;
            }
            100% {
              transform: translateY(0);
              opacity: 0;
            }
          }
          @keyframes sterka_375 {
            0% {
              transform: translateY(0);
              opacity: 0;
            }
            50% {
              transform: translateY(-75px);
              opacity: 1;
            }
            100% {
              transform: translateY(0);
              opacity: 0;
            }
          }
          .green_strelka {
            .green_strelka_big {
              height: 300px;
            }
            .green_strelka_small {
              height: 200px;
            }
            .green_strelka_small_375 {
              height: 250px;
            }
            @keyframes sterkagreenBig {
              0% {
                transform: translateY(0);
              }
              50% {
                transform: translateY(30px);
              }
              100% {
                transform: translateY(0);
              }
            }
            @keyframes sterkagreen {
              0% {
                transform: translateY(0);
                opacity: 0;
              }
              50% {
                transform: translateY(120px);
                opacity: 1;
              }
              100% {
                transform: translateY(0);
                opacity: 0;
              }
            }
            @keyframes sterkagreen_375 {
              0% {
                transform: translateY(0);
                opacity: 0;
              }
              50% {
                transform: translateY(75px);
                opacity: 1;
              }
              100% {
                transform: translateY(0);
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1800px) {
  .homeComponent {
    .messengers_icons {
      flex-direction: row;
      height: auto;
      bottom: 45px;
      top: unset;
      width: 100%;

      a {
        margin-bottom: 0;
        margin-left: 25px;
        margin-right: 25px;
      }
    }
  }
}

@media (max-width: 500px) {
  .homeComponent {
    .messengers_icons {
      display: none;
    }
  }
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.calculator__field:first-child {
  margin-right: 27px;

  @media (max-width: 768px) {
    margin-right: 15px;
  }
}

.valuta_text {
  font-weight: 700;
  margin-bottom: 5px;
}
</style>
