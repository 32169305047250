<template>
  <nav class="menu" :class="{ active: active }">
    <span @click="$emit('close_menu')" class="menu__close_btn"
      ><img :src="require('@/assets/images/icons/close.svg')"
    /></span>
    <ul class="menu__list">
      <li class="menu__item" @click="$emit('close_menu')">
        <a href="/#currency" class="menu__link">Exchange</a>
      </li>
      <li class="menu__item" @click="$emit('close_menu')">
        <a href="/#features" class="menu__link">Features</a>
      </li>
      <li class="menu__item" @click="$emit('close_menu')">
        <a href="/#currencies" class="menu__link">Currencies</a>
      </li>
      <li class="menu__item" @click="$emit('close_menu')">
        <a href="/#advantages" class="menu__link">Advantages</a>
      </li>
      <li class="menu__item" @click="$emit('close_menu')">
        <a href="/#contact" class="menu__link">FAQ</a>
      </li>
    </ul>
    <div class="menu__adds">
      <p class="menu__cap">
        POLICIES AND RULES
        <span class="menu__arrow_dropdown"
          ><img :src="require('@/assets/images/icons/arrow.svg')"
        /></span>
      </p>
      <ul class="menu__list dropdown">
        <li class="menu__item">
          <router-link target="_blank" to="/terms-of-service" class="menu__link"
            >Terms and conditions</router-link
          >
        </li>
        <li class="menu__item">
          <router-link target="_blank" to="/personal-data-processing" class="menu__link"
            >GDPR</router-link
          >
        </li>
        <li class="menu__item">
          <router-link target="_blank" to="/privacy-policy" class="menu__link"
            >Privacy Policy</router-link
          >
        </li>
        <li class="menu__item">
          <router-link target="_blank" to="/aml-kyc" class="menu__link">AML & KYC</router-link>
        </li>
        <li class="menu__item">
          <router-link target="_blank" to="/cookies-policy" class="menu__link">Cookies</router-link>
        </li>
      </ul>
    </div>
    <div class="menu__adds">
      <p class="menu__cap">
        INFORMATION
        <span class="menu__arrow_dropdown"
          ><img :src="require('@/assets/images/icons/arrow.svg')"
        /></span>
      </p>
      <ul class="menu__list dropdown">
        <li class="menu__item">
          <router-link target="_blank" class="menu__link" to="/security"
            >Security
          </router-link>
        </li>
        <li class="menu__item">
          <router-link target="_blank" class="menu__link" to="/verification_process"
            >Verification process
          </router-link>
        </li>
        <li class="menu__item">
          <router-link target="_blank" class="menu__link" to="/regulation"
            >Regulation
          </router-link>
        </li>
        <li class="menu__item">
          <router-link target="_blank" class="menu__link" to="/customer_support"
            >Customer support
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Menu',
  props: {
    active: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      activeDropdown: false
    }
  },
  methods: {
    toggleMenu(e) {
      if (!e.target.closest('.menu__cap')) return
      const menu = e.target.closest('.menu__adds')
      menu.classList.toggle('active')
      const navigation = menu.querySelector('.dropdown')
      if (menu.classList.contains('active')) {
        const height = navigation.scrollHeight
        navigation.style.height = `${height}px`
      } else {
        navigation.style.height = 0
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.toggleMenu)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.toggleMenu)
  }
}
</script>

<style lang="scss" scoped>
.menu {
  overflow: auto;
  position: fixed;
  top: 0;
  left: -500px;
  z-index: 10000;
  height: 100vh;
  background: rgba(3, 4, 30, 0.9);
  padding: 40px;
  box-sizing: border-box;
  width: 300px;
  transition: all 0.5s;

  &.active {
    left: 0;
  }
}

.menu__list {
  list-style-type: none;
  margin-bottom: 30px;
  padding-left: 0;
}

.menu__link {
  color: #fff;
  text-decoration: none;
  font-size: 19px;
  padding: 10px 0;
  display: inline-block;

  &:visited {
    color: #fff;
  }

  &:hover {
    color: #00fb49;
  }
}

.menu__close_btn {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.menu__cap {
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
}

.menu__arrow_dropdown {
  margin-left: 5px;

  img {
    transition: all 0.3s;
  }

  &.active {
    img {
      transform: rotate(-90deg);
    }
  }
}

.menu__adds.active {
  .menu__arrow_dropdown {
    img {
      transform: rotate(-90deg);
    }
  }
}

.dropdown {
  height: 0;
  transition: all 0.3s;
  overflow: hidden;
}


@media (min-width: 1920px) {
  .menu{
    width: 400px;
    padding: 50px;
  }
}
</style>
