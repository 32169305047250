<template>
  <div id="features" class="slider" ref="slider" @mouseleave="slideMouseLeave">
    <div class="slider_item slider_item_one">
      <h1 class="title">
        Safe <br />
        exchange
      </h1>
      <div class="description">
        All kinds of exchanges for you:
        <br />
        <li>Fiat - Crypto</li>
        <li>Crypto - Fiat</li>
        <li>Crypto - Crypto</li>
      </div>
      <img class="hand_one" src="/images/slider/hand_one.svg" alt="hand_one" />
      <img class="hand_two" src="/images/slider/hand_two.svg" alt="hand_two" />
      <picture>
        <source
          :srcset="require('@/assets/images/slider/circel-position-min.webp')"
          type="image/webp"
        />
        <source
          :srcset="require('@/assets/images/slider/circel_position-min.png')"
          type="image/png"
        />
        <img
          class="circel_position"
          :src="require('@/assets/images/slider/circel_position-min.png')"
          alt="circel_position"
        />
      </picture>

      <img
        class="lines lines_one"
        :src="require('@/assets/images/slider/lines-min.png')"
        alt="lines"
      />
    </div>
    <div class="slider_item slider_item--second slider_active">
      <h1 class="title">
        Buy
        <span
          >cryptocurrency <br />
          safely</span
        >
      </h1>
      <div class="description">
        Buying Cryptocurrency

        <br />
        via bank transfer <br />
        SEPA and SWIFT from anywhere <br />
        worldwide
      </div>
      <div class="bank_img">
        <picture>
          <source
            :srcset="require('@/assets/images/slider/bank-min.webp')"
            type="image/webp"
          />
          <source
            :srcset="require('@/assets/images/slider/bank-min.png')"
            type="image/png"
          />
          <img
            :src="require('@/assets/images/slider/bank-min.png')"
            alt="bank"
          />
        </picture>
      </div>
      <img class="stupid_three" src="/images/slider/bitcoin_icon.svg" alt="" />
      <img class="stupid_two" src="/images/slider/bitcoin_icon.svg" alt="" />
      <img class="stupid_big" src="/images/slider/bitcoin_icon.svg" alt="" />
      <picture>
        <source
          :srcset="
            require('@/assets/images/slider/circel-position-blue-min.webp')
          "
          type="image/webp"
        />
        <source
          :srcset="
            require('@/assets/images/slider/circel_position_blue-min.png')
          "
          type="image/png"
        />
        <img
          class="circel_position_blue"
          :src="require('@/assets/images/slider/circel_position_blue-min.png')"
          alt="circel_position"
        />
      </picture>
      <picture>
        <source
          :srcset="require('@/assets/images/slider/lines-min.webp')"
          type="image/webp"
        />
        <source
          :srcset="require('@/assets/images/slider/lines-min.png')"
          type="image/png"
        />
        <img
          class="lines"
          :src="require('@/assets/images/slider/lines-min.png')"
          alt="lines"
        />
      </picture>
      <div class="blur_wrapper"></div>
    </div>
    <div></div>
    <div class="slider_item">
      <h1 class="title">
        Cryptocurrency
        <span>Consignment - Storage</span>
      </h1>
      <div class="description">
        Consignment services <br />
        cryptocurrencies on our <br />
        platform are safe <br />
        and secure.
      </div>
      <div class="seyf_img">
        <picture>
          <source
            :srcset="require('@/assets/images/slider/seyf-min.webp')"
            type="image/webp"
          />
          <source
            :srcset="require('@/assets/images/slider/seyf-min.png')"
            type="image/png"
          />
          <img
            :src="require('@/assets/images/slider/seyf-min.png')"
            alt="seyf"
          />
        </picture>
      </div>
      <picture>
        <source
          :srcset="
            require('@/assets/images/slider/circel-position-blue-min.webp')
          "
          type="image/webp"
        />
        <source
          :srcset="
            require('@/assets/images/slider/circel_position_blue-min.png')
          "
          type="image/png"
        />
        <img
          class="circel_position_blue"
          :src="require('@/assets/images/slider/circel_position_blue-min.png')"
          alt="circel_position"
        />
      </picture>
      <picture>
        <source
          :srcset="require('@/assets/images/slider/lines-min.webp')"
          type="image/webp"
        />
        <source
          :srcset="require('@/assets/images/slider/lines-min.png')"
          type="image/png"
        />
        <img
          class="lines"
          :src="require('@/assets/images/slider/lines-min.png')"
          alt="lines"
        />
      </picture>
    </div>
    <div class="slider_item">
      <h1 class="title">
        We <br />
        are working
        <span>for you!</span>
      </h1>
      <div class="description">
        Customer Service <br />
        24/7 <br />
        Live
      </div>
      <div class="team_img">
        <picture>
          <source
            :srcset="require('@/assets/images/slider/team-min.webp')"
            type="image/webp"
          />
          <source
            :srcset="require('@/assets/images/slider/team-min.png')"
            type="image/png"
          />
          <img
            :src="require('@/assets/images/slider/team-min.png')"
            alt="team_img"
          />
        </picture>
      </div>
      <picture>
        <source
          :srcset="require('@/assets/images/slider/circel-position-red-min.webp')"
          type="image/webp"
        />
        <source
          :srcset="
            require('@/assets/images/slider/circel_position_red-min.png')
          "
          type="image/png"
        />
        <img
          class="circel_position_blue"
          :src="require('@/assets/images/slider/circel_position_red-min.png')"
          alt="circel_position"
        />
      </picture>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      sliderIsShown: false,
    };
  },
  methods: {
    slideMouseLeave(first = false) {
      let slideItem = document.querySelectorAll(".slider_item");
      const slider_item_one = document.querySelector(".slider_item_one");
      const slider_item_two = document.querySelector(".slider_item--second");
      slideItem.forEach(function (item, i) {
        item.classList.remove("slider_active");
      });
      first
        ? slider_item_one.classList.add("slider_active")
        : slider_item_two.classList.add("slider_active");
    },
    getCoords(elem) {
      let box = elem.getBoundingClientRect();

      return {
        top: box.top + window.pageYOffset,
        right: box.right + window.pageXOffset,
        bottom: box.bottom + window.pageYOffset,
        left: box.left + window.pageXOffset,
      };
    },
    detectMob() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ];

      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
    },
  },
  mounted() {
    if (this.detectMob()) {
      document
        .querySelector(".slider_item--second")
        .classList.remove("slider_active");
      document.querySelector(".slider_item_one").classList.add("slider_active");
    }

    window.addEventListener("scroll", () => {
      if (this.detectMob() || this.sliderIsShown) return;
      const windowScroll = document.documentElement.scrollTop;
      const windowHeight = document.documentElement.clientHeight;
      const sliderHeight = this.$refs.slider.clientHeight;
      const positionSlider = this.getCoords(this.$refs.slider);
      if (
        positionSlider.top > windowScroll &&
        positionSlider.bottom - sliderHeight / 2 < windowScroll + windowHeight
      ) {
        this.slideMouseLeave(true);
      }
    });

    let slideItem = document.querySelectorAll(".slider_item");
    slideItem.forEach((item, i) => {
      item.addEventListener("mouseover", () => {
        slideItem.forEach((item, i) => {
          item.classList.remove("slider_active");
        });
        item.classList.add("slider_active");
        if (this.detectMob()) {
          setTimeout(() => {
            const coords = this.getCoords(item);
            window.scrollTo(0, coords.top - 100);
          }, 500);
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.slider {
  max-width: 1736px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 9;

  .slider_item {
    flex: 2;
    height: 814px;
    padding: 0 48px;
    position: relative;
    overflow: hidden;
    transition: 0.8s;
    background: linear-gradient(0deg, #0b0c29, #0b0c29), #d9d9d9;
    box-shadow: -39px 0px 61px rgba(0, 0, 0, 0.25);
    .title {
      font-family: "Sofia Sans", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 89.4%;
      color: #ffffff;
      margin-bottom: 90px;
      margin-top: 160px;
      position: relative;
      z-index: 7;
      transition: 0.8s;
      span {
        display: block;
        font-family: "Sofia Sans", sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 114.9%;
        color: #ffffff;
      }
    }
    .description {
      display: block;
      font-family: "Sofia Sans", sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 22px;
      line-height: 30px;
      color: #ffffff;
      margin-left: -200%;
      transition: 0.8s;
      position: relative;
      z-index: 7;
      li {
        list-style-type: disc;
      }
    }
    .hand_one {
      position: absolute;
      bottom: -18px;
      right: 30px;
      z-index: 3;
      transition: 0.8s;
      transform: rotate(-20deg);
    }
    .hand_two {
      position: absolute;
      top: -100%;
      right: 0px;
      z-index: 2;
      transition: 0.8s;
    }
    .bank_img {
      position: absolute;
      bottom: 0;
      left: 0;
      right: -30%;
      display: flex;
      justify-content: end;
      transition: 0.8s;
      z-index: 2;
      img {
        transition: 0.8s;
      }
    }
    .seyf_img {
      position: absolute;
      bottom: -23px;
      left: 0;
      right: -380px;
      display: flex;
      justify-content: end;
      transition: 0.8s;
      z-index: 5;
      img {
        transition: 0.8s;
      }
    }
    .team_img {
      position: absolute;
      bottom: 0;
      left: 0;
      right: -193px;
      display: flex;
      justify-content: end;
      transition: 0.8s;
      z-index: 3;
      img {
        transition: 0.8s;
      }
    }
    .stupid_big {
      position: absolute;
      bottom: 34px;
      right: 0;
      left: 0;
      margin: 0 auto;
      z-index: 5;
      transition: 0.8s;
    }
    .stupid_two {
      width: 183.88px;
      height: 183.22px;
      filter: blur(2px);
      transform: rotate(8.36deg);
      position: absolute;
      top: 268px;
      right: -82px;
      z-index: 2;
      transition: 0.8s;
    }
    .stupid_three {
      width: 92.84px;
      height: 92.5px;
      filter: blur(8px);
      transform: rotate(-23.75deg);
      position: absolute;
      top: -41px;
      right: 194px;
      z-index: 2;
      transition: 0.8s;
    }
    .circel_position {
      position: absolute;
      right: -845px;
      bottom: 100px;
      transition: 0.8s;
    }
    .circel_position_blue {
      position: absolute;
      right: -645px;
      bottom: 80px;
      transition: 0.8s;
    }
    .lines {
      position: absolute;
      right: -435px;
      bottom: 0;
      mix-blend-mode: overlay;
      transition: 0.8s;
      opacity: 0;
      z-index: 4;
    }
    .blur_wrapper {
      background: linear-gradient(0deg, #03041e, #03041e), #d9d9d9;
      box-shadow: -39px 0px 61px rgba(0, 0, 0, 0.25);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 4;
      opacity: 0.8;
      transition: 0.8s;
    }
  }
  .slider_item_one {
    background: transparent;
    box-shadow: none;
  }
  .slider_active {
    flex: 5;
    .title {
      font-size: 60px;
    }
    .description {
      margin-left: 0;
    }
    .hand_one {
      transform: rotate(0);
      right: 80px;
    }
    .hand_two {
      top: 0;
    }
    .bank_img {
      right: -20%;
      img {
        transform: scale(1.2);
      }
    }
    .team_img {
      transform: scale(1.3);
      right: -103px;
    }
    .seyf_img {
      right: -320px;
      img {
        transform: scale(1.3);
      }
    }
    .stupid_three {
      top: 86px;
      right: 210px;
    }
    .stupid_big {
      left: 165px;
      bottom: -95px;
      transform: rotate(-20.22deg);
    }
    .stupid_two {
      right: 143px;
    }
    .stupid_three {
      right: 210px;
    }
    .circel_position {
      right: -445px;
      bottom: -300px;
    }
    .circel_position_blue {
      right: -445px;
      bottom: -300px;
    }
    .lines {
      right: 0;
      opacity: 0.5;
    }
    .lines_one {
      opacity: 0.2;
    }
    .blur_wrapper {
      opacity: 0.3;
    }
  }
}
@media (max-width: 1400px) {
  .slider {
    .slider_item {
      flex: 1;
      padding: 0 40px;
      .title {
        font-size: 30px;
        span {
          font-size: 25px;
        }
      }
      &:first-child {
        padding-left: 80px;
      }
      .hand_one {
        bottom: -106px;
        right: -41px;
        transform: scale(0.6) rotate(-15deg);
      }
      .stupid_big {
        height: 200px;
      }
      .seyf_img {
        right: -240px;
        img {
          height: 400px;
        }
      }
      .team_img {
        right: -169px;
        img {
          height: 300px;
        }
      }
      .bank_img {
        img {
          height: 400px;
        }
      }
    }
    .slider_active {
      flex: 4;
      .title {
        font-size: 35px;
      }
      .description {
        font-size: 18px;
        line-height: 24px;
      }
      .hand_one {
        bottom: -58px;
        right: 30px;
        transform: scale(0.9) rotate(-30deg);
      }
      .stupid_big {
        height: 250px;
      }
      .seyf_img {
        img {
          height: 500px;
        }
      }
      .team_img {
        right: -169px;
        img {
          height: 450px;
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .slider {
    flex-direction: column;
    .slider_item {
      min-height: 105px;
      height: 105px;
      border-radius: 16px;
      margin-bottom: -26px;
      z-index: 2;
      .title {
        height: 10px;
        margin-bottom: 15px;
        margin-top: 15px;
        font-size: 24px;
        span {
          font-size: 18px;
        }
      }
      &:first-child {
        padding-left: 40px;
      }
      .description {
        margin-left: -100%;
      }
      .hand_one {
        height: 132px;
        bottom: -3px;
        right: 78px;
      }
      .hand_two {
        top: -254px;
        transform: scale(0.2);
      }
      .bank_img {
        right: 0;
        bottom: -70px;
        img {
          transform: scale(0.5);
        }
      }
      .stupid_big {
        transform: scale(0.5);
      }
      .seyf_img {
        right: -11px;
        bottom: -52px;
        img {
          height: 434px;
        }
      }
      .team_img {
        img {
          height: auto;
          width: auto;
          margin-bottom: -300px;
        }
      }
    }
    .slider_active {
      min-height: 760px;
      height: 760px;
      .title {
        height: 70px;
        font-size: 35px;
        margin: 90px 0 40px;
      }
      .description {
        margin-left: 0;
        font-size: 18px;
        line-height: 24px;
      }
      .hand_one {
        height: 400px;
        bottom: -21px;
        right: 30px;
      }
      .hand_two {
        top: 0;
        transform: scale(1);
      }
      .bank_img {
        right: 0;
        bottom: 0;
        img {
          height: 600px;
          transform: scale(1);
        }
      }
      .seyf_img {
        right: -294px;
        bottom: 47px;
        img {
          height: 633px;
        }
      }
      .stupid_big {
        transform: scale(1);
      }
      .team_img {
        right: 100px;
        img {
          height: 650px;
          margin-bottom: 0;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .slider {
    .slider_item {
      .lines {
        height: 300px;
      }
    }
    .slider_active {
      .team_img {
        img {
          height: 430px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .slider {
    grid-template-columns: repeat(1, 1fr);
    .slider_item {
      .lines {
        height: 264px;
      }
      .team_img {
        right: 0;
      }
    }
  }
}
@media (max-width: 576px) {
  .slider {
    .slider_item {
      height: 500px;
      .title {
        font-size: 24px;
        margin: 15px 0;
      }
      .description {
        font-size: 18px;
        line-height: 22px;
      }
      .hand_one {
        // height: 150px;
      }
      .hand_two {
        top: -200px;
        height: 500px;
      }
      .circel_position {
        height: 800px;
        bottom: 0;
        left: -39px;
      }
      .circel_position_blue {
        height: 800px;
        bottom: 0;
        left: -39px;
      }
      .stupid_big {
        height: 150px;
      }
      .stupid_two {
        width: auto;
        height: 100px;
        right: 0;
        top: 230px;
      }
      .bank_img {
        right: 0;
        img {
          height: 370px;
        }
      }
      .team_img {
        img {
          margin-bottom: -179px;
          height: 381px;
        }
      }
      .seyf_img {
        right: -178px;
        img {
          height: 400px;
        }
      }
    }
    .slider_active {
      min-height: 550px;
      .title {
        margin: 40px 0 30px;
      }
      .circel_position {
        height: 800px;
        bottom: -338px;
        left: -39px;
      }
      .hand_two {
        top: 0;
        height: 320px;
      }
      .circel_position_blue {
        height: 800px;
        bottom: -338px;
        left: -39px;
      }
      .stupid_big {
        left: 33px;
        bottom: -56px;
      }
      .hand_one {
        height: 250px;
        bottom: 0;
      }
      .stupid_two {
        right: 143px;
        top: 230px;
      }
      .bank_img {
        right: -50px;
      }
      .seyf_img {
        right: -130px;
      }
      .team_img {
        img {
          margin-bottom: 0;
          height: 344px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .slider_item {
    transition: 0.4s;
  }
}
</style>
