<template>
  <div id="contact">
    <div class="main_container">
      <div class="contact">
        <div class="contact_left">
          <div class="contact_title animation_left">FAQ</div>
          <div class="contact_left_in animation_left">
            <div
              class="question"
              v-for="(questionItem, index) of question"
              :key="index"
            >
              <div class="contact_question">
                <div class="question_text">{{ questionItem.description }}</div>
                <div class="question_button" @click="questionActive">
                  <img
                    class="contact_question_icon"
                    src="/images/contact/next_icon.svg"
                  />
                </div>
              </div>
              <div class="contact_question_slideDown">
                <div class="title">{{ questionItem.description }}</div>
                <div class="description">
                  {{ questionItem.questionActive }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="contact_right">
          <div class="contact_title animation_right">Any questions?</div>
          <div class="contact_description animation_right">
            Fill in the form and our manager will contact you!
          </div>
          <div class="contact_right_in animation_right">
            <form ref="form" @submit.prevent="sendQuestion" class="contact_form">
              <div class="form_top">
                <div class="contact_input">
                  <label for="0">Your name</label>
                  <input id="0" required type="text" placeholder="Enter your name" />
                </div>
                <div class="contact_input">
                  <label for="1">Your E-mail</label>
                  <input required type="email" placeholder="Enter your E-mail" />
                </div>
              </div>
              <div class="contact_question_inpput">
                <label for="3">Your question</label>
                <input required type="text" placeholder="Type in your question" />
              </div>
              <div class="contact_right__tools">
                <button class="btn">Send</button>
              </div>
            </form>
            <div class="contact_bottom">
              <div class="contact_bottom_text">Or write to us at:</div>
              <a target="_blank" href="https://t.me/fixoneglobal_support" class="contact_link contact_link2">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.65832 12.3681C9.30231 10.7804 11.7322 9.73375 12.948 9.22809C16.4194 7.78424 17.1407 7.53341 17.6109 7.52511C17.7143 7.5233 17.9455 7.54894 18.0952 7.67047C18.3174 7.85075 18.3187 8.24205 18.294 8.50104C18.1059 10.4776 17.2919 15.2741 16.8778 17.4879C16.7026 18.4246 16.3575 18.7387 16.0236 18.7694C15.2977 18.8362 14.7465 18.2897 14.0434 17.8289C12.9432 17.1077 12.3217 16.6588 11.2538 15.955C10.0196 15.1418 10.8197 14.6948 11.523 13.9643C11.7071 13.7731 14.9054 10.864 14.9673 10.6001C14.9751 10.5671 14.9822 10.444 14.9092 10.3791C14.8361 10.3141 14.7282 10.3363 14.6504 10.354C14.54 10.379 12.7825 11.5407 9.3778 13.8389C8.87893 14.1815 8.42707 14.3484 8.02224 14.3397C7.5759 14.33 6.7174 14.0873 6.07917 13.8798C5.29636 13.6254 4.67419 13.4908 4.72839 13.0587C4.75661 12.8336 5.06657 12.6034 5.65832 12.3681Z"
                    fill="white"
                  />
                </svg>
                <svg
                  width="51"
                  height="51"
                  viewBox="0 0 51 51"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_114_623)">
                    <path
                      d="M25.5 34C32.4036 34 38 28.4036 38 21.5C38 14.5964 32.4036 9 25.5 9C18.5964 9 13 14.5964 13 21.5C13 28.4036 18.5964 34 25.5 34Z"
                      fill="url(#paint0_linear_114_623)"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M18.6583 21.3681C22.3023 19.7804 24.7322 18.7338 25.948 18.2281C29.4194 16.7842 30.1407 16.5334 30.6109 16.5251C30.7143 16.5233 30.9455 16.5489 31.0952 16.6705C31.3174 16.8507 31.3187 17.2421 31.294 17.501C31.1059 19.4776 30.2919 24.2741 29.8778 26.4879C29.7026 27.4246 29.3575 27.7387 29.0236 27.7694C28.2977 27.8362 27.7465 27.2897 27.0434 26.8289C25.9432 26.1077 25.3217 25.6588 24.2538 24.955C23.0196 24.1418 23.8197 23.6948 24.523 22.9643C24.7071 22.7731 27.9054 19.864 27.9673 19.6001C27.9751 19.5671 27.9822 19.444 27.9092 19.3791C27.8361 19.3141 27.7282 19.3363 27.6504 19.354C27.54 19.379 25.7825 20.5407 22.3778 22.8389C21.8789 23.1815 21.4271 23.3484 21.0222 23.3397C20.5759 23.33 19.7174 23.0873 19.0792 22.8798C18.2964 22.6254 17.6742 22.4908 17.7284 22.0587C17.7566 21.8336 18.0666 21.6034 18.6583 21.3681Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_114_623"
                      x="0"
                      y="0"
                      width="51"
                      height="51"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy="4" />
                      <feGaussianBlur stdDeviation="6.5" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.160784 0 0 0 0 0.658824 0 0 0 0 0.913725 0 0 0 1 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_114_623"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_114_623"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_114_623"
                      x1="25.5"
                      y1="9"
                      x2="25.5"
                      y2="33.8047"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#2AABEE" />
                      <stop offset="1" stop-color="#229ED9" />
                    </linearGradient>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalComponent @close="showModal = false" v-if="showModal">
      {{ modalText }}
    </ModalComponent>
  </div>
</template>

<script>
import ModalComponent from '@/components/common/ModalComponent.vue'
import axios from 'axios'

export default {
  components: {
    ModalComponent
  },
  data() {
    return {
      showModal: false,
      modalText: null,
      question: [
        {
          description: "Why is it safe to make exchanges with us?",
          questionActive:
            "We are a fully licensed company and we are legal and safe to do all exchange transactions with. You can rest assured that your money is safe with FIXONE Exchange.",
        },
        {
          description: "I paid for the transaction, but it was cancelled. What should I do?",
          questionActive:
            "You need to contact our support team and report your transaction number. We will restore the transaction and check the payment.",
        },
        {
          description: "What is the transaction fee ?",
          questionActive:
            "Our service's commissions are already included in the exchange rate and are displayed at the application stage. Fees will depend on the amount exchanged and the country in which you are located. You can see the details on the page 'Terms & Conditions'.",
        },
        {
          description: "How are the transactions protected from fraud?",
          questionActive:
            "Given that we are a licensed company that's saying a lot. We have implemented all possible security instruments in our platform. So we have implemented different financial services which can detect fraud and take appropriate actions.",
        }
      ],
    };
  },
  methods: {
    sendQuestion() {
      const formData = new FormData(this.$refs.form)
      axios({
        method: 'post',
        url: 'https://profixone.com/api/report',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
        .then(() => {
          this.modalText = 'your email has been sent'
        })
        .catch(() => {
          this.modalText = 'oops! something went wrong, try again'
        })
        .finally(() => {
          this.showModal = true
          setTimeout(() => (this.showModal = false), 5000)
        })
    },
    questionActive(e) {
      const activeQuestion = document.querySelector('.question.active')
      if (activeQuestion) {
        let questionText = activeQuestion.querySelector(".contact_question_slideDown");
        let contact_question_icon = document.querySelector(
          ".contact_question_icon"
        );
        questionText.classList.toggle("contact_question_active");
        contact_question_icon.classList.toggle("question_button_active");
        activeQuestion.classList.remove('active')
      }else {
        const question = e.target.closest('.question')
        question.classList.add('active')
        let questionText = question.querySelector(".contact_question_slideDown");
        let contact_question_icon = document.querySelector(
          ".contact_question_icon"
        );
        questionText.classList.toggle("contact_question_active");
        contact_question_icon.classList.toggle("question_button_active");
      }
      
    },
  },
};
</script>
<style scoped lang="scss">

.qeustion.active{
  z-index: 10;
}

#contact {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  color: #fff;
  z-index: 1;
  .main_container {
    max-width: 1293px;
    margin: 0 auto;
    .contact {
      display: flex;
      justify-content: space-between;
      margin-bottom: 84px;
      gap: 20px;
      .contact_left {
        max-width: 636px;
        width: 100%;
        .contact_title {
          font-family: "Sofia Sans", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 35px;
          line-height: 42px;
          text-align: center;
          color: #ffffff;
          margin-bottom: 37px;
        }
        .contact_left_in {
          background: rgba(24, 25, 49, 0.12);
          backdrop-filter: blur(19px);
          border-radius: 10px;
          padding: 43px 20px 60px 20px;
          width: 100%;
          position: relative;
          .contact_question {
            max-width: 515px;
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            mix-blend-mode: normal;
            border: 1px solid #ffffff;
            border-radius: 10px;
            padding: 10px 12px 10px 16px;
            margin-bottom: 18px;
            height: 40px;
            overflow: hidden;
            .question_text {
              font-family: "Montserrat", sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              color: #ffffff;
              display: block;
            }
            .question_button {
              background: transparent;
              border: none;
              cursor: pointer;
              display: block;
              transition: 0.5s;
            }
            .contact_question_icon {
              position: relative;
              transition: 0.5s;

              &.question_button_active{
                z-index: 7;
              }
            }
            &:nth-child(2) {
              position: relative;
              z-index: 1;
            }
            &:nth-child(3) {
              position: relative;
              z-index: 1;
            }
            &:nth-child(4) {
              position: relative;
              z-index: 1;
            }
            .question_button_active {
              transform: rotate(180deg);
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
          .contact_question_slideDown {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            max-width: 515px;
            height: 40px;
            padding: 10px 12px 10px 16px;
            mix-blend-mode: normal;
            border: 1px solid #ffffff;
            border-radius: 10px;
            z-index: -1;
            opacity: 0;
            right: 0;
            margin: 0 auto;
            top: 43px;
            max-height: 236px;
            background: #03041e;
            overflow: hidden;
            transition: 0.5s;
            .title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-family: "Montserrat", sans-serif;
              font-style: normal;
              font-weight: 700;
              font-size: 14px;
              line-height: 17px;
              color: #ffffff;
              margin-bottom: 18px;
              padding-right: 35px;
            }
            .contact_question_icon {
              cursor: pointer;
            }
            .description {
              font-family: "Montserrat", sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              color: #ffffff;
            }
          }
          .contact_question_active {
            height: 236px;
            z-index: 6;
            opacity: 1;
          }
        }
      }
      .contact_right {
        max-width: 633px;
        width: 100%;
        .contact_title {
          font-family: "Sofia Sans", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 40px;
          line-height: 48px;
          color: #ffffff;
          text-transform: capitalize;
          text-align: center;
        }
        .contact_description {
          font-family: "Sofia Sans", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 22px;
          color: #ffffff;
          text-align: center;
          margin-bottom: 10px;
        }
        .contact_right_in {
          width: 100%;
          background: rgba(24, 25, 49, 0.12);
          backdrop-filter: blur(19px);
          border-radius: 10px;
          padding: 58px 20px 39px 20px;
          .contact_form {
            max-width: 400px;
            width: 100%;
            margin: 0 auto;
            .form_top {
              display: flex;
              gap: 11px;
              .contact_input {
                max-width: 192px;
                width: 100%;
                label {
                  display: block;
                  font-family: "Montserrat", sans-serif;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 20px;
                  text-align: center;
                  color: #ffffff;
                  margin-bottom: 8px;
                }
                input {
                  display: block;
                  width: 100%;
                  padding: 11px 27px;
                  font-family: "Montserrat", sans-serif;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 17px;
                  color: #ffffff;
                  mix-blend-mode: overlay;
                  background: transparent;
                  border: 1px solid #ffffff;
                  border-radius: 10px;
                  outline: none;
                  opacity: 0.7;
                  &::placeholder {
                    color: #ffffff;
                    opacity: 0.5;
                  }
                }
              }
            }
            .contact_question_inpput {
              label {
                display: block;
                font-family: "Montserrat", sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #ffffff;
                margin-bottom: 8px;
                margin-top: 9px;
              }
              input {
                display: block;
                width: 100%;
                padding: 11px 27px;
                font-family: "Montserrat", sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #ffffff;
                mix-blend-mode: overlay;
                background: transparent;
                border: 1px solid #ffffff;
                border-radius: 10px;
                outline: none;
                opacity: 0.7;
                &::placeholder {
                  color: #ffffff;
                  opacity: 0.5;
                }
              }
            }
          }
          .contact_bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 400px;
            margin: 0 auto;
            margin-top: 49px;
            .contact_bottom_text {
              font-family: "Montserrat", sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              text-align: center;
              color: #ffffff;
              max-width: 221px;
              width: 100%;
            }
            .contact_link {
              display: inline-block;
              position: relative;
              svg {
                transition: 0.5s;
                &:last-child {
                  position: absolute;
                  left: -17px;
                  top: -13px;
                  opacity: 0;
                }
              }
              &:hover {
                svg {
                  &:last-child {
                    position: absolute;
                    opacity: 1;
                  }
                }
              }
            }
            .contact_link2 {
              svg {
                &:last-child {
                  left: -12px;
                  top: -10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1400px) {
  .main_container {
    padding: 0 20px;
  }
}
@media (max-width: 880px) {
  #contact {
    .main_container {
      .contact {
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
        .contact_left {
          .contact_title {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
@media (max-width: 500px) {
  #contact {
    .main_container {
      .contact {
        margin-bottom: 30px;
        .contact_left {
          .contact_title {
            margin-bottom: 10px;
            font-size: 24px;
            line-height: 28px;
          }
          .contact_left_in {
            padding: 25px 15px 25px 15px;
            .contact_question_slideDown {
              top: 22px;
              max-width: 425px;
              max-height: 218px;
              .description {
                font-size: 12px;
                line-height: 14px;
              }
            }
          }
        }
        .contact_right {
          .contact_title {
            font-size: 24px;
            line-height: 28px;
          }
          .contact_description {
            font-size: 14px;
            line-height: 16px;
          }
          .contact_right_in {
            padding: 25px 15px 25px 15px;
            .contact_form {
              .form_top {
                flex-direction: column;
                .contact_input {
                  max-width: 100%;
                  input {
                    width: 100%;
                  }
                }
              }
            }
            .contact_bottom {
              gap: 10px;
              .contact_bottom_text {
                font-size: 14px;
                line-height: 16px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 450px) {
  #contact {
    .main_container {
      .contact {
        .contact_left {
          .contact_left_in {
            .contact_question_slideDown {
              top: 22px;
              max-width: 379px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 413px) {
  #contact {
    .main_container {
      .contact {
        .contact_left {
          .contact_left_in {
            .contact_question_slideDown {
              top: 22px;
              max-width: 344px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 402px) {
  #contact {
    .main_container {
      .contact {
        .contact_left {
          .contact_left_in {
            .contact_question_slideDown {
              top: 22px;
              max-width: 330px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 392px) {
  #contact {
    .main_container {
      .contact {
        .contact_left {
          .contact_left_in {
            .contact_question_slideDown {
              top: 22px;
              max-width: 321px;
            }
          }
        }
      }
    }
  }
}

.contact_link{
  margin-right: auto;
}

.contact_right__tools{
  margin: 20px 0;
  display: flex;
  justify-content: center;
}


</style>
