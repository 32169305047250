<template>
  <div class="fix_select">
    <vSelect
      appendToBody
      ref="select"
      v-model="selected"
      :options="options"
      label="name"
      :clearable="false"
    >
      <template #list-header>
        <div class="fix_select__wrapper_header">
          <div class="fix_select__header">{{ title }}</div>
          <button class="fix_select__btn_close" @click="close">
            <svg
              width="14px"
              height="14px"
              viewBox="0 0 14 14"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <title>close</title>
              <g
                id="Icons"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="Rounded"
                  transform="translate(-281.000000, -3481.000000)"
                >
                  <g
                    id="Navigation"
                    transform="translate(100.000000, 3378.000000)"
                  >
                    <g
                      id="-Round-/-Navigation-/-close"
                      transform="translate(176.000000, 98.000000)"
                    >
                      <g>
                        <polygon
                          id="Path"
                          points="0 0 24 0 24 24 0 24"
                        ></polygon>
                        <path
                          d="M18.3,5.71 C17.91,5.32 17.28,5.32 16.89,5.71 L12,10.59 L7.11,5.7 C6.72,5.31 6.09,5.31 5.7,5.7 C5.31,6.09 5.31,6.72 5.7,7.11 L10.59,12 L5.7,16.89 C5.31,17.28 5.31,17.91 5.7,18.3 C6.09,18.69 6.72,18.69 7.11,18.3 L12,13.41 L16.89,18.3 C17.28,18.69 17.91,18.69 18.3,18.3 C18.69,17.91 18.69,17.28 18.3,16.89 L13.41,12 L18.3,7.11 C18.68,6.73 18.68,6.09 18.3,5.71 Z"
                          id="🔹-Icon-Color"
                          fill="#00fb49"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </button>
        </div>
      </template>
      <template #selected-option="{ name, image, symbol }">
        <div class="fix_select__option">
          <img class="fix_select__image" :src="image" alt="" /> {{ name }}
          <span v-if="name">({{ symbol }})</span
          ><span v-else>{{ symbol }}</span>
        </div>
      </template>
      <template #option="{ name, symbol, image }">
        <div class="fix_select__option fix_select__option--choice">
          <img class="fix_select__image" :src="image" alt="" /> {{ name }}
          <span v-if="name">({{ symbol }})</span
          ><span v-else>{{ symbol }}</span>
        </div>
      </template>
    </vSelect>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";

export default {
  name: "FixSelect",
  components: {
    vSelect,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [Object, null],
      default: () => {},
    },
    preset: {
      type: [String],
      default: () => "",
    },
    title: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  watch: {
    options() {
      this.setPreset();
    },
    value(value) {
      this.selected = value
    },
    selected(value) {
      this.$emit("input", value);
    },
  },
  methods: {
    close() {
      console.log(this.$refs.select.$refs.search.blur());
    },
    setPreset() {
      this.selected = this.options.find((item) => item.symbol === this.preset);
    },
  },
  mounted() {
    if (this.preset) this.setPreset();
  },
};
</script>

<style lang="scss">
.fix_select__wrapper_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fix_select__btn_close {
  margin-right: 15px;
  background: transparent;
  border: none;
}

.fix_select {
  width: 200px;
}

.vs__dropdown-menu {
  background: rgba(3, 4, 30, 0.9);
}

.vs__open-indicator {
  fill: #fff;
}

.vs__dropdown-option,
.vs__selected {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.vs__dropdown-toggle {
  padding: 5px 0;
  background: rgba(217, 217, 217, 0.11);
  border-radius: 10px;
}

.fix_select__image {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.fix_select__option {
  display: flex;
  align-items: center;
}

.fix_select__wrapper_header {
  display: none;
}

@media (max-width: 768px) {
  .fix_select__option--choice {
    font-size: 22px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .vs__dropdown-menu {
    position: fixed;
    bottom: 0;
    top: unset !important;
    width: 100% !important;
    height: 70vh;
    max-height: unset !important;
    left: 0 !important;
  }

  .fix_select__wrapper_header{
    display: flex;
  }
  
  .fix_select__header {
    display: block;
    font-size: 26px;
    font-weight: 700;
    color: #fff;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 10px;
  }
}
</style>
