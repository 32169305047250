import Vue from 'vue'
import Vuex from 'vuex'
import api from '../api/'
import freeCurrencies from '@/api/freeCurrencies'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currencies: [],
    courses: {},
    cryptoCurrenies: [
      {
        image: require('@/assets/currencies/usdt.png'),
        name: 'Tether',
        symbol: 'USDT'
      },
      {
        image: require('@/assets/currencies/btc.png'),
        name: 'Bitcoin',
        symbol: 'BTC'
      },
      {
        image: require('@/assets/currencies/eth.png'),
        name: 'Ethereum',
        symbol: 'ETH'
      },
      {
        image: require('@/assets/currencies/xrp.png'),
        name: 'XRP',
        symbol: 'XRP'
      },
      {
        image: require('@/assets/currencies/matic.png'),
        name: 'Polygon',
        symbol: 'MATIC'
      },
      {
        image: require('@/assets/currencies/sol.png'),
        name: 'Solana',
        symbol: 'SOL'
      },
      {
        image: require('@/assets/currencies/ada.png'),
        name: 'Cordana',
        symbol: 'ADA'
      }
    ],
    CBCurrencies: [
      {
        image: require('@/assets/currencies/dollar.svg'),
        name: '',
        symbol: 'USD'
      },
      {
        image: require('@/assets/currencies/euro.svg'),
        name: '',
        symbol: 'EUR'
      },
      {
        image: require('@/assets/currencies/pound.svg'),
        name: '',
        symbol: 'GBP'
      }
    ]
  },
  getters: {
    currencies(state) {
      return state.currencies
    },
    courses(state) {
      return state.courses
    },
    CBCurrencies(state) {
      return state.CBCurrencies
    },
    cryptoCurrenies(state) {
      return state.cryptoCurrenies
    }
  },
  mutations: {
    SET_CURRENCIES(state, data) {
      state.currencies = data
    },
    SET_COURSES(state, data) {
      state.courses = data
    }
  },
  actions: {
    async getCurrencies({ commit }) {
      return api.getPrices().then(response => {
        commit('SET_CURRENCIES', response.data)
        return response
      })
    },
    async getFreeCurrencies({ commit }) {
      return freeCurrencies.getCurrencies().then(response => {
        commit('SET_COURSES', response.data.data)
      })
    }
  },
  modules: {}
})
