<template>
  <div class="modal">
    <div @click="$emit('close')" class="modal__close">close</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ModalComponent'
}
</script>

<style lang="scss" scoped>
.modal {
  z-index: 10001;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background: rgba(24, 25, 49, 0.37);
  backdrop-filter: blur(27px);
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 50px;
  box-sizing: border-box;
  max-width: 100%;
  width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__close{
  font-size: 0;
  background: url('../../assets/images/close.svg') no-repeat center center;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
</style>
