<template>
  <div class="currency">
    <div id="currency" class="currency__navigation_anchor"></div>
    <div class="give currency_item animation_left">
      <h3 class="title">Giving away</h3>
      <div class="calculator">
        <div class="calculator_center">
          <div class="calculator__field">
            <p class="valuta_text">Currency</p>
            <FixSelect
              :options="givingAwayCurrencies"
              preset="USD"
              title="Giving away"
              v-model="givingAway"
              @input="calculateReciving"
            />
          </div>
          <div class="calculator__field">
            <p class="valuta_text">Amount</p>
            <div class="form_control">
              <input type="number"  onFocus="this.select()" v-model="userGiving" @input="calculateReciving" />
            </div>
          </div>
        </div>
      </div>
      <div class="currency_bottom">
        Min: 0.3 Max: 3.2 <br />
        1 BTC = 50,000 USD
      </div>
    </div>
    <button @click="reverseExchanging" class="currency_exchange animation_center">
      <img src="/images/home/currency_exchange.svg" alt="currency_exchange" />
    </button>
    <div class="receive currency_item animation_right">
      <h3 class="title">Receive</h3>
      <div class="calculator">
        <div class="calculator_center">
          <div class="calculator__field">
            <p class="valuta_text">Currency</p>
            <FixSelect
              :options="reciveCurrencies"
              preset="USDT"
              title="Recieve"
              v-model="reciveCurrency"
              @input="calculateGiving"
            />
          </div>
          <div class="calculator__field">
            <p class="valuta_text">Amount</p>
            <div class="form_control">
              <input type="text"  onFocus="this.select()" v-model="userReciving" @input="calculateGiving" />
            </div>
          </div>
        </div>
        <a target="_blank" href="https://office.fixoneglobal.com/register" class="exchange_btn btn">
          Exchange
          <svg
            width="24"
            height="7"
            viewBox="0 0 24 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="3" width="24" height="1" fill="white" />
            <line x1="23.7" y1="3.4" x2="19.7" y2="0.4" stroke="white" />
            <line x1="19.7" y1="6.6" x2="23.7" y2="3.6" stroke="white" />
          </svg>
        </a>
      </div>
      <div class="currency_bottom">
        Min: 2000 <br />
        Reserve: 1,500,000 USD
      </div>
    </div>
  </div>
</template>

<script>
import FixSelect from './common/FixSelect.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Calculator',
  components: {
    FixSelect
  },
  data() {
    return {
      givingAway: null,
      reciveCurrency: null,
      userReciving: null,
      userGiving: null
    }
  },
  computed: {
    ...mapGetters(['cryptoCurrenies', 'CBCurrencies', 'courses', 'currencies']),
    reciveCurrencies() {
      return [...this.cryptoCurrenies, ...this.CBCurrencies]
    },
    givingAwayCurrencies() {
      return [...this.CBCurrencies, ...this.cryptoCurrenies]
    },
    givingCurrency() {
      if (this.givingAway === null) return 1
      const symbol = this.givingAway.symbol
      if (this.courses && this.courses[symbol]) return 1 / this.courses[symbol]
      const crypto = this.currencies.find(
        currency => currency.symbol === symbol
      )
      const price = parseFloat(crypto?.price)
      return isNaN(price) ? 1 : price
    },
    recivingCurrency() {
      if (this.reciveCurrency === null) return 1
      const symbol = this.reciveCurrency.symbol
      if (this.courses && this.courses[symbol]) return 1 / this.courses[symbol]
      const crypto = this.currencies.find(
        currency => currency.symbol === symbol
      )
      const price = parseFloat(crypto?.price)
      return isNaN(price) ? 1 : price
    },
  },
  methods: {
    reverseExchanging() {
      const givingAway = this.givingAway
      this.givingAway = this.reciveCurrency
      this.reciveCurrency = givingAway
    },
    calculateReciving() {
      this.userReciving = this.userGiving / this.recivingCurrency * this.givingCurrency
    },
    calculateGiving() {
      this.userGiving = this.userReciving * this.recivingCurrency / this.givingCurrency
    }
  }
}
</script>

<style lang="scss" scoped>
.currency {
  position: relative;
  max-width: 1345px;
  margin: 0 auto;
  position: relative;
  z-index: 11;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 35px;
  .currency_item {
    background: rgba(24, 25, 49, 0.37);
    backdrop-filter: blur(27px);
    border-radius: 10px;
    padding: 16px 28px 18px;
    .title {
      font-family: 'Sofia Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 30px;
      color: #ffffff;
      margin-bottom: 33px;
    }
    .calculator {
      display: flex;
      align-items: center;
      margin-bottom: 23px;
      .valuta_text {
        font-family: 'Sofia Sans', sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 17px;
        color: #ffffff;
        margin-right: 17px;
      }
      .calculator_center {
        display: flex;
      }
      .select_bitcoin {
        position: relative;
        z-index: 10;

        .select_bitcoin_btn {
          background: rgba(217, 217, 217, 0.11);
          padding: 8px 6px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          transition: 0.5s;
        }
        .select_bitcoin_btn_active {
          border-radius: 10px 10px 0 0;
        }
        .valute_icon {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #ffb800;
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
        .valuta_icon_dollor {
          background: #12aa02;
        }
        .valuta_name {
          font-family: 'Sofia Sans', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 25px;
          color: #ffffff;
          margin: 0 23px 0 7px;
        }
        .select_icon {
          cursor: pointer;
        }
        .select_menu {
          width: 100%;
          height: 0;
          padding: 0;
          position: absolute;
          top: 100%;
          left: 0;
          background: rgba(217, 217, 217, 0.11);
          border-radius: 0 0 10px 10px;
          transition: 0.5s;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            width: 0;
          }
          .option {
            display: flex;
            align-items: center;
            margin-bottom: 14px;
            cursor: pointer;
            opacity: 0;
            z-index: -1;
            transition: 0.5s;

            &:last-child {
              margin-bottom: 10px;
            }
          }
        }
        .select_menu_dollor {
          width: 100%;
          height: 0;
          padding: 0;
          position: absolute;
          top: 100%;
          left: 0;
          background: rgba(217, 217, 217, 0.11);
          border-radius: 0 0 10px 10px;
          transition: 0.5s;
          overflow-y: scroll;
          padding: 0px;
          &::-webkit-scrollbar {
            width: 0;
          }
          .option {
            display: flex;
            align-items: center;
            margin-bottom: 14px;
            cursor: pointer;
            opacity: 0;
            z-index: -1;
            transition: 0.5s;

            &:last-child {
              margin-bottom: 10px;
            }
          }
        }
        .select_menu_active {
          height: 160px;
          padding: 6px;
          .option {
            opacity: 1;
          }
        }
      }
      .form_control {
        width: 100%;
        max-width: 200px;
        input {
          width: 100%;
          background: rgba(217, 217, 217, 0.11);
          border-radius: 10px;
          border: 0;
          outline: 0;
          padding: 11px 21px;
          font-family: 'Sofia Sans', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          &::placeholder {
            font-family: 'Sofia Sans', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #ffffff50;
          }
        }
      }
      .exchange_btn {
        background: linear-gradient(270deg, #12aa02 0%, #7ec492 100%);
        border-radius: 8px;
        transition: 0.5s;
        padding: 10px 14px;
        border: 0;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        display: flex;
        align-items: center;
        margin-left: 35px;
        margin-top: auto;
        cursor: pointer;
        svg {
          margin-left: 13px;
        }

        &:hover {
          background: linear-gradient(270deg, #12aa02 0%, #ad1212 100%);
        }
      }
    }
    .currency_bottom {
      font-family: 'Sofia Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #767794;
    }
  }
  .give {
    position: relative;
    z-index: 20;
  }

  .receive {
    position: relative;
    z-index: 19;
    .calculator {
      .select_bitcoin {
        .option {
          .valute_icon {
            background: #12aa02;
          }
        }
      }
    }
  }
  .currency_exchange {
    width: 62px;
    height: 62px;
    background: #03041e;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    z-index: 20;
    cursor: pointer;
  }
}

@media (max-width: 1200px) {
  .currency {
    max-width: 650px;
    margin: 0 auto;
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
  }
}

@media (max-width: 768px) {
  .currency {
    margin-top: 110px;
    .currency_item {
      padding: 16px;
      .title {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 15px;
      }
      .calculator {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: start;
        .valuta_text {
          width: 100%;
          margin-bottom: 10px;
          font-size: 12px;
          line-height: 25px;
        }
        .select_bitcoin {
          .valuta_name {
            font-size: 14px;
            line-height: 25px;
          }
        }
        .form_control {
          input {
            font-size: 14px;
            &::placeholder {
              font-size: 11px;
            }
          }
        }
        .exchange_btn {
          margin-left: 105px;
          margin-top: 15px;
          font-size: 14px;
          line-height: 18px;
        }
      }
      .currency_bottom {
        font-size: 12px;
        line-height: 15px;
      }
    }
    .currency_exchange {
      width: 52px;
      height: 52px;
      top: -50px;
      img {
        height: 20px;
      }
    }
  }
}

.calculator__field:first-child {
  margin-right: 27px;

  @media (max-width: 768px) {
    margin-right: 15px;
  }
}

.valuta_text {
  font-weight: 700;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .calculator_center{
    flex-direction: column;
  }
}

#currency {
  position: absolute;
  top: -150px;
}
</style>
