import axios from "axios"

const freeCurrenciesApi = axios.create({
  baseURL: 'https://api.freecurrencyapi.com/',
  headers: 'Content-Type: application/json'
})

export default {
  getCurrencies() {
    return freeCurrenciesApi.get('v1/latest?apikey=3E30u2iZhlbCWqoL09mn8ZvfyrUXfCpZnpjgyCZA')
  }
}