<template>
  <div id="currencies" class="main_container">
    <div class="cards">
      <div class="card_item" :class="itemOne ? 'item_active' : ''">
        <img
          class="Clip_path animation_left"
          src="/images/Clip_path.svg"
          alt="Clip_path"
        />
        <div class="card_item_in">
          <h2 class="title">Top 10 cryptocurrencies</h2>
          <div class="item_container">
            <div class="item" v-for="(item, index) of top10" :key="index">
              <div class="item_left">
                <span><img :src="item.image" alt="item.name" /></span>
                <p>
                  {{ item.symbol }}
                </p>
              </div>
              <p class="summa">$ {{ item.price }}</p>
            </div>
          </div>
          <svg
            @click="item(1)"
            class="select_icon"
            width="11"
            height="7"
            viewBox="0 0 11 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.2458 4.5358L5.9558 0.295798C5.86283 0.20207 5.75223 0.127676 5.63037 0.0769072C5.50852 0.0261385 5.37781 0 5.2458 0C5.11379 0 4.98308 0.0261385 4.86122 0.0769072C4.73936 0.127676 4.62876 0.20207 4.5358 0.295798L0.295798 4.5358C0.20207 4.62876 0.127675 4.73936 0.0769067 4.86122C0.026138 4.98308 0 5.11379 0 5.2458C0 5.37781 0.026138 5.50852 0.0769067 5.63037C0.127675 5.75223 0.20207 5.86283 0.295798 5.9558C0.483161 6.14205 0.736612 6.24659 1.0008 6.24659C1.26498 6.24659 1.51844 6.14205 1.7058 5.9558L5.2458 2.4158L8.7858 5.9558C8.97206 6.14054 9.22346 6.24469 9.4858 6.2458C9.6174 6.24656 9.74787 6.22133 9.86971 6.17157C9.99154 6.12181 10.1024 6.04848 10.1958 5.9558C10.2929 5.86618 10.3712 5.75821 10.4263 5.63814C10.4814 5.51806 10.5122 5.38826 10.5169 5.25622C10.5215 5.12419 10.4999 4.99254 10.4534 4.86889C10.4069 4.74523 10.3363 4.63202 10.2458 4.5358Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div class="card_item" :class="itemTwo ? 'item_active' : ''">
        <img
          class="Clip_path animation_center"
          src="/images/g215.svg"
          alt="Clip_path"
        />
        <div class="card_item_in">
          <h2 class="title">Growth leaders</h2>
          <div class="item_container">
            <div class="item" v-for="(item, index) of growthLeaders" :key="index">
              <div class="item_left">
                <span><img :src="item.image" alt="betcoin" /></span>
                {{ item.symbol }}
              </div>
              <div class="item_right">
                <svg
                  width="11"
                  height="7"
                  viewBox="0 0 11 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.295797 5.95579C0.48316 6.14204 0.736611 6.24658 1.0008 6.24658C1.26498 6.24658 1.51843 6.14204 1.7058 5.95579L5.2958 2.41579L8.8358 5.95579C9.02316 6.14204 9.27661 6.24658 9.5408 6.24658C9.80498 6.24658 10.0584 6.14204 10.2458 5.95579C10.3395 5.86283 10.4139 5.75223 10.4647 5.63037C10.5155 5.50851 10.5416 5.3778 10.5416 5.24579C10.5416 5.11378 10.5155 4.98307 10.4647 4.86121C10.4139 4.73935 10.3395 4.62875 10.2458 4.53579L6.0058 0.295789C5.91283 0.202061 5.80223 0.127667 5.68037 0.0768981C5.55852 0.0261293 5.42781 -9.03013e-06 5.2958 -9.04167e-06C5.16379 -9.05321e-06 5.03308 0.0261292 4.91122 0.0768981C4.78936 0.127667 4.67876 0.202061 4.5858 0.295789L0.295797 4.53579C0.202069 4.62875 0.127675 4.73935 0.0769063 4.86121C0.0261375 4.98307 -1.80832e-06 5.11378 -1.81986e-06 5.24579C-1.8314e-06 5.3778 0.0261374 5.50851 0.0769063 5.63037C0.127675 5.75222 0.202069 5.86283 0.295797 5.95579Z"
                    fill="#00FB49"
                  />
                </svg>

                <p class="percent">{{ item.price_change }}</p>
              </div>
            </div>
          </div>
          <svg
            @click="item(2)"
            class="select_icon"
            width="11"
            height="7"
            viewBox="0 0 11 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.2458 4.5358L5.9558 0.295798C5.86283 0.20207 5.75223 0.127676 5.63037 0.0769072C5.50852 0.0261385 5.37781 0 5.2458 0C5.11379 0 4.98308 0.0261385 4.86122 0.0769072C4.73936 0.127676 4.62876 0.20207 4.5358 0.295798L0.295798 4.5358C0.20207 4.62876 0.127675 4.73936 0.0769067 4.86122C0.026138 4.98308 0 5.11379 0 5.2458C0 5.37781 0.026138 5.50852 0.0769067 5.63037C0.127675 5.75223 0.20207 5.86283 0.295798 5.9558C0.483161 6.14205 0.736612 6.24659 1.0008 6.24659C1.26498 6.24659 1.51844 6.14205 1.7058 5.9558L5.2458 2.4158L8.7858 5.9558C8.97206 6.14054 9.22346 6.24469 9.4858 6.2458C9.6174 6.24656 9.74787 6.22133 9.86971 6.17157C9.99154 6.12181 10.1024 6.04848 10.1958 5.9558C10.2929 5.86618 10.3712 5.75821 10.4263 5.63814C10.4814 5.51806 10.5122 5.38826 10.5169 5.25622C10.5215 5.12419 10.4999 4.99254 10.4534 4.86889C10.4069 4.74523 10.3363 4.63202 10.2458 4.5358Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div class="card_item" :class="itemThree ? 'item_active' : ''">
        <img
          class="Clip_path animation_right"
          src="/images/g6269.svg"
          alt="Clip_path"
        />
        <div class="card_item_in">
          <h2 class="title">Falling leaders</h2>
          <div class="item_container">
            <div class="item" v-for="(item, index) of fallingLeaders" :key="index">
              <div class="item_left">
                <span><img :src="item.image" alt="betcoin" /></span>
                {{ item.symbol }}
              </div>
              <div class="item_right">
                <svg
                  width="11"
                  height="7"
                  viewBox="0 0 11 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2458 0.290792C10.0584 0.104542 9.80498 0 9.5408 0C9.27661 0 9.02316 0.104542 8.8358 0.290792L5.2458 3.83079L1.7058 0.290792C1.51844 0.104542 1.26498 0 1.0008 0C0.736612 0 0.483161 0.104542 0.295798 0.290792C0.20207 0.383755 0.127675 0.494356 0.0769067 0.616216C0.026138 0.738075 0 0.868781 0 1.00079C0 1.1328 0.026138 1.26351 0.0769067 1.38537C0.127675 1.50723 0.20207 1.61783 0.295798 1.71079L4.5358 5.95079C4.62876 6.04452 4.73936 6.11891 4.86122 6.16968C4.98308 6.22045 5.11379 6.24659 5.2458 6.24659C5.37781 6.24659 5.50852 6.22045 5.63037 6.16968C5.75223 6.11891 5.86283 6.04452 5.9558 5.95079L10.2458 1.71079C10.3395 1.61783 10.4139 1.50723 10.4647 1.38537C10.5155 1.26351 10.5416 1.1328 10.5416 1.00079C10.5416 0.868781 10.5155 0.738075 10.4647 0.616216C10.4139 0.494356 10.3395 0.383755 10.2458 0.290792Z"
                    fill="#C14141"
                  />
                </svg>

                <p class="percent increase">{{ item.price_change }}</p>
              </div>
            </div>
          </div>
          <svg
            @click="item(3)"
            class="select_icon"
            width="11"
            height="7"
            viewBox="0 0 11 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.2458 4.5358L5.9558 0.295798C5.86283 0.20207 5.75223 0.127676 5.63037 0.0769072C5.50852 0.0261385 5.37781 0 5.2458 0C5.11379 0 4.98308 0.0261385 4.86122 0.0769072C4.73936 0.127676 4.62876 0.20207 4.5358 0.295798L0.295798 4.5358C0.20207 4.62876 0.127675 4.73936 0.0769067 4.86122C0.026138 4.98308 0 5.11379 0 5.2458C0 5.37781 0.026138 5.50852 0.0769067 5.63037C0.127675 5.75223 0.20207 5.86283 0.295798 5.9558C0.483161 6.14205 0.736612 6.24659 1.0008 6.24659C1.26498 6.24659 1.51844 6.14205 1.7058 5.9558L5.2458 2.4158L8.7858 5.9558C8.97206 6.14054 9.22346 6.24469 9.4858 6.2458C9.6174 6.24656 9.74787 6.22133 9.86971 6.17157C9.99154 6.12181 10.1024 6.04848 10.1958 5.9558C10.2929 5.86618 10.3712 5.75821 10.4263 5.63814C10.4814 5.51806 10.5122 5.38826 10.5169 5.25622C10.5215 5.12419 10.4999 4.99254 10.4534 4.86889C10.4069 4.74523 10.3363 4.63202 10.2458 4.5358Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/index";
import { mapGetters } from "vuex";

export default {
  name: "Crystals",
  data() {
    return {
      itemOne: false,
      itemTwo: false,
      itemThree: false,
    };
  },
  computed: {
    ...mapGetters(['currencies']),
    top10() {
      return this.currencies.slice(0, 10)
    },
    growthLeaders() {
      const currencies = JSON.parse(JSON.stringify(this.currencies))  
      const sortedCurrencies = currencies.sort((a, b) => parseFloat(b.price_change)  - parseFloat(a.price_change) )
      return sortedCurrencies.slice(0, 10)
    },
    fallingLeaders() {
      const currencies = JSON.parse(JSON.stringify(this.currencies))  
      const sortedCurrencies = currencies.sort((a, b) => parseFloat(a.price_change)  - parseFloat(b.price_change) )
      return sortedCurrencies.slice(0, 10)
    }
  },
  methods: {
    item(num) {
      if (num == 1) {
        this.itemOne = !this.itemOne;
      } else if (num == 2) {
        this.itemTwo = !this.itemTwo;
      } else {
        this.itemThree = !this.itemThree;
      }
    },
  },
  mounted() {
    setInterval(this.getPrices, 60000);
    const observerLeft = new IntersectionObserver((entires) => {
      entires.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animation_left_active");
        } else {
          entry.target.classList.remove("animation_left_active");
        }
      });
    });
    const observerRight = new IntersectionObserver((entires) => {
      entires.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animation_right_active");
        } else {
          entry.target.classList.remove("animation_right_active");
        }
      });
    });
    const observerCenter = new IntersectionObserver((entires) => {
      entires.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animation_center_active");
        } else {
          entry.target.classList.remove("animation_center_active");
        }
      });
    });

    let animation_left = document.querySelectorAll(".animation_left");
    let animation_right = document.querySelectorAll(".animation_right");
    let animation_center = document.querySelectorAll(".animation_center");

    animation_left.forEach((el) => observerLeft.observe(el));
    animation_right.forEach((el) => observerRight.observe(el));
    animation_center.forEach((el) => observerCenter.observe(el));
  },
};
</script>

<style scoped lang="scss">
.main_container {
  max-width: 1293px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
.cards {
  padding: 150px 0 75px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 26px;
  .card_item {
    text-align: center;
    .Clip_path {
      margin-bottom: 62px;
    }
    .card_item_in {
      overflow: hidden;
      padding: 16px 27px 18px;
      background: rgba(24, 25, 49, 0.14);
      backdrop-filter: blur(27px);
      border-radius: 10px;
      .title {
        font-family: "Sofia Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        color: #ffffff;
        margin-bottom: 20px;
      }
      .item_container {
        height: 220px;
        overflow: hidden;
        transition: 0.5s;
      }
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba(217, 217, 217, 0.11);
        border-radius: 10px;
        padding: 8px 17px 8px 6px;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 21px;
        }

        .item_left {
          font-family: "Montserrat", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #ffffff;
          span {
            min-width: 24px;
            min-height: 24px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: #ffb800;
            border-radius: 50%;
            margin-right: 13px;
          }
        }
        .summa {
          font-family: "Montserrat", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #767794;
        }
        .item_right {
          display: flex;
          align-items: center;
          font-family: "Montserrat", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #00fb49;
          svg {
            margin-right: 10px;
          }
          .increase {
            font-family: "Montserrat", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #c14141;
          }
        }
      }
      .select_icon {
        transition: 0.5s;
        transform: rotate(180deg);
        cursor: pointer;
      }
    }
  }
  .item_active {
    .card_item_in {
      .item_container {
        height: 560px;
      }
      .select_icon {
        transform: rotate(0);
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 1350px) {
  .main_container {
    padding: 0 20px;
  }
}
@media (max-width: 1150px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 50px;
    .card_item {
      &:last-child {
        transform: translateX(50%);
      }
    }
  }
}
@media (max-width: 770px) {
  .cards {
    grid-template-columns: repeat(1, 1fr);
    .card_item {
      &:last-child {
        transform: translateX(0);
      }
    }
  }
}
@media (max-width: 576px) {
  .cards {
    padding: 100px 0 40px;

    .card_item {
      .card_item_in {
        .item_active {
          height: 500px;
        }
        .title {
          font-size: 20px;
          line-height: 25px;
        }
        .item {
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 15px;
          }
          .item_left {
            font-size: 14px;
            line-height: 18px;
          }
          .summa {
            font-size: 14px;
            line-height: 18px;
          }
        }
        .item_container {
          height: 199px;
        }
      }
    }
    .item_active {
      .card_item_in {
        .item_container {
          height: 510px;
        }
      }
    }
  }
}

.item_left {
  display: flex;
  align-items: center;

  p {
    font-family: "Montserrat", sans-serif;
  }

  span {
    margin-top: auto;
    margin-bottom: auto;
  }

  img {
    max-width: 24px;
  }
}
</style>
