import axios from "axios"

const clientApi = axios.create({
  baseURL: 'https://api.fixone.exchange/api/',
  headers: 'Content-Type: application/json'
})

export default {
  getPrices() {
    return clientApi.get('prices')
  }
}