<template>
  <div id="app">
    <div class="router_wiev">
      <router-view />
    </div>
		<CookiesAccept />
		<Menu :active="activeMenu" @close_menu="activeMenu = false" />
		<div v-if="activeMenu" @click="activeMenu = false" class="overlay"></div>
  </div>
</template>

<script>
import CookiesAccept from './components/CookiesAccept.vue';
import Menu from './components/Menu.vue';
import { eventBus } from './main';

export default {
	name: 'App',
	components: {
		CookiesAccept,
		Menu
	},
	data() {
		return {
			activeMenu: false
		}
	},
  mounted() {
		eventBus.$on('open_menu', () => {this.activeMenu = true})
		this.$store.dispatch('getCurrencies')
		setInterval(() => this.$store.dispatch('getCurrencies'), 60000)
		this.$store.dispatch('getFreeCurrencies')
    const observerLeft = new IntersectionObserver((entires) => {
      entires.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animation_left_active");
        } else {
          entry.target.classList.remove("animation_left_active");
        }
      });
    });
    const observerRight = new IntersectionObserver((entires) => {
      entires.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animation_right_active");
        } else {
          entry.target.classList.remove("animation_right_active");
        }
      });
    });
    const observerCenter = new IntersectionObserver((entires) => {
      entires.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animation_center_active");
        } else {
          entry.target.classList.remove("animation_center_active");
        }
      });
    });
    const animationbottom = new IntersectionObserver((entires) => {
      entires.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animation_bottom_active");
        } else {
          entry.target.classList.remove("animation_bottom_active");
        }
      });
    });

    let animation_left = document.querySelectorAll(".animation_left");
    let animation_right = document.querySelectorAll(".animation_right");
    let animation_center = document.querySelectorAll(".animation_center");
    let animation_bottom = document.querySelectorAll(".animation_bottom");

    animation_left.forEach((el) => observerLeft.observe(el));
    animation_right.forEach((el) => observerRight.observe(el));
    animation_center.forEach((el) => observerCenter.observe(el));
    animation_bottom.forEach((el) => animationbottom.observe(el));
  },
};
</script>

<style lang="scss">
/* This stylesheet generated by Transfonter (https://transfonter.org) on October 3, 2017 10:05 PM */

@font-face {
	font-family: 'Montserrat';
	src: url('@/assets/fonts/Montserrat/Montserrat-Light.eot');
	src: local('Montserrat Light'), local('Montserrat-Light'),
		url('@/assets/fonts/Montserrat/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Montserrat/Montserrat-Light.woff') format('woff'),
		url('@/assets/fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('@/assets/fonts/Montserrat/Montserrat-MediumItalic.eot');
	src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
		url('@/assets/fonts/Montserrat/Montserrat-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Montserrat/Montserrat-MediumItalic.woff') format('woff'),
		url('@/assets/fonts/Montserrat/Montserrat-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('@/assets/fonts/Montserrat/Montserrat-Thin.eot');
	src: local('Montserrat Thin'), local('Montserrat-Thin'),
		url('@/assets/fonts/Montserrat/Montserrat-Thin.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Montserrat/Montserrat-Thin.woff') format('woff'),
		url('@/assets/fonts/Montserrat/Montserrat-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('@/assets/fonts/Montserrat/Montserrat-ExtraLightItalic.eot');
	src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
		url('@/assets/fonts/Montserrat/Montserrat-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Montserrat/Montserrat-ExtraLightItalic.woff') format('woff'),
		url('@/assets/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('@/assets/fonts/Montserrat/Montserrat-BoldItalic.eot');
	src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
		url('@/assets/fonts/Montserrat/Montserrat-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Montserrat/Montserrat-BoldItalic.woff') format('woff'),
		url('@/assets/fonts/Montserrat/Montserrat-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('@/assets/fonts/Montserrat/Montserrat-SemiBold.eot');
	src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
		url('@/assets/fonts/Montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Montserrat/Montserrat-SemiBold.woff') format('woff'),
		url('@/assets/fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('@/assets/fonts/Montserrat/Montserrat-ExtraLight.eot');
	src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
		url('@/assets/fonts/Montserrat/Montserrat-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Montserrat/Montserrat-ExtraLight.woff') format('woff'),
		url('@/assets/fonts/Montserrat/Montserrat-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('@/assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.eot');
	src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'),
		url('@/assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.woff') format('woff'),
		url('@/assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('@/assets/fonts/Montserrat/Montserrat-Italic.eot');
	src: local('Montserrat Italic'), local('Montserrat-Italic'),
		url('@/assets/fonts/Montserrat/Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Montserrat/Montserrat-Italic.woff') format('woff'),
		url('@/assets/fonts/Montserrat/Montserrat-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('@/assets/fonts/Montserrat/Montserrat-Bold.eot');
	src: local('Montserrat Bold'), local('Montserrat-Bold'),
		url('@/assets/fonts/Montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Montserrat/Montserrat-Bold.woff') format('woff'),
		url('@/assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('@/assets/fonts/Montserrat/Montserrat-LightItalic.eot');
	src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
		url('@/assets/fonts/Montserrat/Montserrat-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Montserrat/Montserrat-LightItalic.woff') format('woff'),
		url('@/assets/fonts/Montserrat/Montserrat-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('@/assets/fonts/Montserrat/Montserrat-BlackItalic.eot');
	src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'),
		url('@/assets/fonts/Montserrat/Montserrat-BlackItalic.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Montserrat/Montserrat-BlackItalic.woff') format('woff'),
		url('@/assets/fonts/Montserrat/Montserrat-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('@/assets/fonts/Montserrat/Montserrat-SemiBoldItalic.eot');
	src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
		url('@/assets/fonts/Montserrat/Montserrat-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Montserrat/Montserrat-SemiBoldItalic.woff') format('woff'),
		url('@/assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('@/assets/fonts/Montserrat/Montserrat-Regular.eot');
	src: local('Montserrat Regular'), local('Montserrat-Regular'),
		url('@/assets/fonts/Montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Montserrat/Montserrat-Regular.woff') format('woff'),
		url('@/assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('@/assets/fonts/Montserrat/Montserrat-Medium.eot');
	src: local('Montserrat Medium'), local('Montserrat-Medium'),
		url('@/assets/fonts/Montserrat/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Montserrat/Montserrat-Medium.woff') format('woff'),
		url('@/assets/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('@/assets/fonts/Montserrat/Montserrat-ExtraBold.eot');
	src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
		url('@/assets/fonts/Montserrat/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Montserrat/Montserrat-ExtraBold.woff') format('woff'),
		url('@/assets/fonts/Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('@/assets/fonts/Montserrat/Montserrat-Black.eot');
	src: local('Montserrat Black'), local('Montserrat-Black'),
		url('@/assets/fonts/Montserrat/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Montserrat/Montserrat-Black.woff') format('woff'),
		url('@/assets/fonts/Montserrat/Montserrat-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('@/assets/fonts/Montserrat/Montserrat-ThinItalic.eot');
	src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'),
		url('@/assets/fonts/Montserrat/Montserrat-ThinItalic.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Montserrat/Montserrat-ThinItalic.woff') format('woff'),
		url('@/assets/fonts/Montserrat/Montserrat-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

// /* This stylesheet generated by Transfonter (https://transfonter.org) on July 4, 2017 12:38 PM */

@font-face {
	font-family: 'Neris';
	src: url('@/assets/fonts/Neris/Neris-BlackItalic.eot');
	src: local('Neris-Black Italic'), local('Neris-BlackItalic'),
		url('@/assets/fonts/Neris/Neris-BlackItalic.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Neris/Neris-BlackItalic.woff') format('woff'),
		url('@/assets/fonts/Neris/Neris-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Neris';
	src: url('@/assets/fonts/Neris/Neris-Black.eot');
	src: local('Neris-Black'),
		url('@/assets/fonts/Neris/Neris-Black.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Neris/Neris-Black.woff') format('woff'),
		url('@/assets/fonts/Neris/Neris-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Neris';
	src: url('@/assets/fonts/Neris/Neris-Thin.eot');
	src: local('Neris-Thin'),
		url('@/assets/fonts/Neris/Neris-Thin.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Neris/Neris-Thin.woff') format('woff'),
		url('@/assets/fonts/Neris/Neris-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Neris';
	src: url('@/assets/fonts/Neris/Neris-BoldItalic.eot');
	src: local('Neris-Bold Italic'), local('Neris-BoldItalic'),
		url('@/assets/fonts/Neris/Neris-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Neris/Neris-BoldItalic.woff') format('woff'),
		url('@/assets/fonts/Neris/Neris-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Neris';
	src: url('@/assets/fonts/Neris/Neris-SemiBold.eot');
	src: local('Neris-SemiBold'),
		url('@/assets/fonts/Neris/Neris-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Neris/Neris-SemiBold.woff') format('woff'),
		url('@/assets/fonts/Neris/Neris-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Neris';
	src: url('@/assets/fonts/Neris/Neris-ThinItalic.eot');
	src: local('Neris-Thin Italic'), local('Neris-ThinItalic'),
		url('@/assets/fonts/Neris/Neris-ThinItalic.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Neris/Neris-ThinItalic.woff') format('woff'),
		url('@/assets/fonts/Neris/Neris-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Neris';
	src: url('@/assets/fonts/Neris/Neris-LightItalic.eot');
	src: local('Neris-Light Italic'), local('Neris-LightItalic'),
		url('@/assets/fonts/Neris/Neris-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Neris/Neris-LightItalic.woff') format('woff'),
		url('@/assets/fonts/Neris/Neris-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Neris';
	src: url('@/assets/fonts/Neris/Neris-Light.eot');
	src: local('Neris-Light'),
		url('@/assets/fonts/Neris/Neris-Light.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Neris/Neris-Light.woff') format('woff'),
		url('@/assets/fonts/Neris/Neris-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Neris';
	src: url('@/assets/fonts/Neris/Neris-SemiBoldItalic.eot');
	src: local('Neris-SemiBold Italic'), local('Neris-SemiBoldItalic'),
		url('@/assets/fonts/Neris/Neris-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('@/assets/fonts/Neris/Neris-SemiBoldItalic.woff') format('woff'),
		url('@/assets/fonts/Neris/Neris-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

  @import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:ital,wght@0,1;0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,1;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Sofia Sans';
}
.router_wiev {
  overflow: hidden;
  background: #03041e;
}
.animation_left {
  transform: translateX(-100%);
  opacity: 0;
  transition: 2s;
}
.animation_right {
  transform: translateX(100%);
  opacity: 0;
  transition: 2s;
}
.animation_bottom {
  transform: translateY(200px);
  opacity: 0;
  transition: 1.5s;
}
.animation_center {
  transform: scale(0.1);
  transition: 1.5s;
  opacity: 0;
}
.animation_left_active {
  transform: translateX(0);
  opacity: 1;
}
.animation_right_active {
  transform: translateX(0);
  opacity: 1;
}
.animation_center_active {
  transform: scale(1);
  opacity: 1;
}
.animation_bottom_active{
  transform: translateY(0);
  opacity: 1;
}

@media (max-width: 768px) {
  .animation_left {
  transition: 1s;
}
.animation_right {
  transition: 1s;
}
.animation_bottom {
  transition: 0.75s;
}
.animation_center {
  transition: 0.75s;
}
}


a{
	transition: color .3s;
	&:hover{
		color: #00fb49 !important;
	}
}

body{
	background: #03041E;
}


.home{
	h1{
		text-align: left;
	}
}

.btn{
	display: block;
	background-image: linear-gradient(270deg, #12AA02 0%, #7EC492 100%);
	border-radius: 8px;
	border: none;
	cursor: pointer;
  font-family: 'Sofia Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.19em;
  color: #ffffff;
	padding: 9px 17px;
	position: relative;
	z-index: 1;
	text-decoration: none;

	
	&::before{
		content: '';
		display: block;
		opacity: 0;
		background-image: linear-gradient(270deg, #12AA02 0%, #AD1212 100%);
		transition: opacity .5s;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		position: absolute;
		border-radius: 8px;
		z-index: -1;
	}

	&:hover{
		color: #fff;
		&::before{
			opacity: 1;
		}
	}
}

a.btn:hover{
	color: #fff !important;
}

.overlay{
	position: fixed;
	left: 0;
	top: 0;
	background: rgba(0,0,0, .7);
	width: 100vw;
	height: 100vh;
	z-index: 9999;
}
</style>
