import Vue from "vue";
import VueRouter from "vue-router";
import HomeWiev from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeWiev,
  },
  {
    path: '/cookies-policy',
    name: 'cookies',
    component: () => import('../views/Cookies.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/terms-of-service',
    name: 'terms',
    component: () => import('../views/TermsOfService.vue')
  },
  {
    path: '/aml-kyc',
    name: 'amlkyc',
    component: () => import('../views/AMLKYC.vue')
  },
  {
    path: '/personal-data-processing',
    name: 'personaldata',
    component: () => import('../views/PersonalDataProcessing.vue')
  },
  {
    path: '/security',
    name: 'security',
    component: () => import('../views/Security.vue')
  },
  {
    path: '/customer_support',
    name: 'customersupport',
    component: () => import('../views/CustomerSupport.vue')
  },
  {
    path: '/verification_process',
    name: 'verificationprocess',
    component: () => import('../views/VerificationProcess.vue')
  },
  {
    path: '/regulation',
    name: 'regulation',
    component: () => import('../views/Regulation.vue')
  },
  {
    path: '/problem_report',
    name: 'problemreport',
    component: () => import('../views/ProblemReport.vue')
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


// router.afterEach((to) => {
//   if (to.name !== 'home') {
//     window.scrollTo(0, 0)
//   }
// })

export default router;
