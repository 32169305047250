<template>
  <div class="home">
    <Header @open_menu="$emit('open_menu')" />
    <HomeComponent />
    <Slider />
    <ComponentsThree />
    <Contact />
    <Footer />
    <picture>
      <source :srcset="require('@/assets/images/footer/bg_earth-min.webp')" type="image/webp">
      <source :srcset="require('@/assets/images/footer/bg_earth-min.png')" type="image/png"> 
      <img :src="require('@/assets/images/footer/bg_earth-min.png')" />
    </picture>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import HomeComponent from "@/components/HomeComponent.vue";
import Slider from "@/components/Slider.vue";
import Contact from "@/components/Contact.vue";
import Footer from "@/components/Footer.vue";
import ComponentsThree from "@/components/ComponentsThree.vue";


export default {
  name: "HomeView",
  components: {
    Header,
    HomeComponent,
    Slider,
    Footer,
    Contact,
    ComponentsThree,
  },
};
</script>

<style scoped lang="scss">
.home {
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  img {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0;
  }
}
</style>
