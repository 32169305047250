<template>
  <div class="componentsThree">
    <Cryptels />
    <div class="componentsTwo">
      <Advantages />
      <CounterUp />
      <div class="red_circle2 circel" data-speed="3"></div>
    </div>
    <div class="green_circle1 circel" data-speed="2"></div>
    <div class="green_circle2 circel" data-speed="5"></div>
    <div class="green_circle3 circel" data-speed="-5"></div>
    <div class="red_circle1 circel" data-speed="-7"></div>

    <picture>
      <source :srcset="require('@/assets/images/advantages/bg-circle-green-min.webp')" type="image/webp">
      <source :srcset="require('@/assets/images/advantages/bg_circle_green-min.png')" type="image/png">
      <img class="bg_green" :src="require('@/assets/images/advantages/bg_circle_green-min.png')" />
    </picture>
    <picture>
      <source :srcset="require('@/assets/images/advantages/bg-circle-red-min.webp')" type="image/webp">
      <source :srcset="require('@/assets/images/advantages/bg_circle_red-min.png')" type="image/png">
      <img class="bg_red" :src="require('@/assets/images/advantages/bg_circle_red-min.png')" />
    </picture>
    <picture>
      <source :srcset="require('@/assets/images/advantages/bg-line-min.webp')" type="image/webp">
      <source :srcset="require('@/assets/images/advantages/bg_line-min.png')" type="image/png">
      <img class="bg_line" :src="require('@/assets/images/advantages/bg_line-min.png')" />
    </picture>
  </div>
</template>

<script>
import Advantages from "@/components/Advantages.vue";
import Cryptels from "@/components/Cryptels.vue";
import CounterUp from "@/components/CounterUp.vue";
export default {
  components: {
    CounterUp,
    Advantages,
    Cryptels,
  },

  mounted() {
    document.addEventListener("mousemove", function (e) {
      this.querySelectorAll(".circel").forEach((circel) => {
        const speed = circel.getAttribute("data-speed");
        const x = (window.innerWidth - e.pageX * speed) / 100;
        const y = (window.innerHeight - e.pageY * speed) / 100;
        circel.style.transform = `translateX(${x}px) translateY(${y}px)`;
      });
    });
  },
};
</script>
<style scoped lang="scss">
.componentsThree {
  position: relative;
  .bg_line {
    position: absolute;
    top: 0;
    z-index: 0;
  }
  .bg_green {
    position: absolute;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    transform: translateY(450px);
  }
  .green_circle1,
  .green_circle2,
  .green_circle3 {
    position: absolute;
    background: #57f409;
  }
  .green_circle1 {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    left: 212px;
    top: 10px;
  }
  .green_circle2 {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    top: 754px;
    right: 250px;
  }
  .green_circle3 {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    bottom: 400px;
    left: 234px;
  }
  .red_circle1,
  .red_circle2 {
    position: absolute;
    background: linear-gradient(0deg, #ff0000, #ff0000),
      linear-gradient(0deg, #ff0000, #ff0000),
      linear-gradient(0deg, #ff0000, #ff0000),
      linear-gradient(0deg, #ff0000, #ff0000), #ff0000;
    width: 17px;
    height: 17px;
    border-radius: 50%;
  }
  .red_circle1 {
    right: 300px;
    bottom: 250px;
  }
  .componentsTwo {
    position: relative;
    .red_circle2 {
      top: 0;
      left: 30%;
    }
  }
  .bg_red {
    position: absolute;
    bottom: -20%;
    right: -35%;
  }
}
@media (max-width: 576px) {
  .componentsThree {
    .green_circle1 {
      left: 130px;
      top: 54px;
    }
  }
}
</style>
