<template>
  <div id="advantages">
    <div class="main_container">
      <div class="advantages">
        <div class="advantages_title">Advantages</div>
        <div class="advantages_description">
          FIXONE Global Trading's exchange service
        </div>
        <div class="advantages_card_wrapper">
          <div class="advantages_card animation_left">
            <div class="advantages_img">
              <img src="/images/advantages/icon1.svg" />
            </div>
            <div class="advantages_card_body">
              <h4>
                Flexibility
 <br />
 system
              </h4>
              <p>Fully automated payment processing</p>
            </div>
          </div>
          <div class="advantages_card animation_left">
            <div class="advantages_img">
              <img src="/images/advantages/icon2.svg" />
            </div>
            <div class="advantages_card_body">
              <h4>
                Privacy
client  <br />
confidentiality
              </h4>
              <p>
                We care about your anonymity and confidentiality. We do not share or store your personal data
              </p>
            </div>
          </div>
          <div class="advantages_card animation_bottom">
            <div class="advantages_img">
              <img src="/images/advantages/icon3.svg" />
            </div>
            <div class="advantages_card_body">
              <h4>
                Speed of processing  <br />
                your application
              </h4>
              <p>
                We value your time, so we process all requests as quickly as possible
              </p>
            </div>
          </div>
          <div class="advantages_card animation_right">
            <div class="advantages_img">
              <img src="/images/advantages/icon4.svg" />
            </div>
            <div class="advantages_card_body">
              <h4>
                Profitable  <br />
                rates
              </h4>
              <p>
                We take a professional approach to pricing and offer the best exchange rates
              </p>
            </div>
          </div>
          <div class="advantages_card animation_right">
            <div class="advantages_img">
              <img src="/images/advantages/icon5.svg" />
            </div>
            <div class="advantages_card_body">
              <h4>
                Online support  <br />
                24/7
              </h4>
              <p>
                We provide reliable support and are ready to answer any question
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="advantages_mobile animation_bottom">
      <div class="advantages_title">Advantages</div>
      <div class="advantages_description">
        FIXONE Global Trading's exchange service
      </div>
      <div>
        <swiper
          :slides-per-view="1.3"
          :space-between="30"
          :loop="false"
        >
          <swiper-slide
            class="card_mobile"
            v-for="(swiperCardItem, index) of swiperCard"
            :key="index"
          >
            <div class="advantages_card">
              <div class="advantages_img">
                <img :src="swiperCardItem.src" />
              </div>
              <div class="advantages_card_body">
                <h4>
                  {{ swiperCardItem.title }}
                </h4>
                <p>{{ swiperCardItem.description }}</p>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
import { Navigation, Pagination } from "swiper";

import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";

// Import Swiper styles
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperCard: [
        {
          src: `/images/advantages/icon1.svg`,
          title: "Flexibility",
          description: "Fully automated payment processing",
        },
        {
          src: `/images/advantages/icon2.svg`,
          title: "Privacy client confidentiality",
          description:
            "We care about your anonymity and confidentiality. We do not share or store your personal data",
        },
        {
          src: `/images/advantages/icon3.svg`,
          title: "Speed of processing your application",
          description:
            "We value your time, so we process all requests as quickly as possible",
        },
        {
          src: `/images/advantages/icon4.svg`,
          title: "Profitable rates",
          description:
            "We take a professional approach to pricing and offer the best exchange rates",
        },
        {
          src: `/images/advantages/icon5.svg`,
          title: "Online support 24/7",
          description:
            "We provide reliable support and are ready to answer any question",
        },
      ],
    };
  },
  methods: {
    getImageUrl(imageId) {
      return `/images/advantages/icon${imageId}.svg`;
    },
  },
};
</script>
<style scoped lang="scss">
#advantages {
  max-width: 1920px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  .main_container {
    max-width: 1291px;
    margin: 0 auto;
    .advantages {
      .advantages_title {
        font-family: "Sofia Sans", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 45px;
        line-height: 42px;
        text-align: center;
        color: #ffffff;
      }
      .advantages_description {
        font-family: "Sofia Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
      }
      .advantages_card_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: start;
        gap: 5px;
        margin-top: 55px;
        margin-bottom: 66px;
        .advantages_card {
          padding: 38px 8px 20px 8px;
          background: rgba(24, 25, 49, 0.37);
          backdrop-filter: blur(27px);
          border-radius: 10px;
          max-width: 247px;
          width: 100%;
          transition: 0.9s all ease;
          height: 100vh;
          max-height: 345px;
          .advantages_img {
            text-align: center;
            margin-bottom: 54px;
          }
          .advantages_card_body {
            h4 {
              font-family: "Sofia Sans", sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: 22px;
              line-height: 26px;
              text-align: center;
              color: #00fb49;
              margin-bottom: 24px;
            }
            p {
              font-family: "Sofia Sans", sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              text-align: center;
              color: #ffffff;
              max-width: 198px;
              margin: 0 auto;
            }
          }
          &:hover {
            box-shadow: 0px 16px 34px rgba(3, 251, 76, 0.35);
            transform: scale(1.03);
          }
        }
      }
    }
  }
  .advantages_mobile {
    display: none;
    .advantages_title {
      font-family: "Sofia Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: #ffffff;
    }
    .advantages_description {
      font-family: "Sofia Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 40px;
    }
  }
}
@media (max-width: 1400px) {
  .main_container {
    padding: 0 20px;
  }
}
@media (max-width: 992px) {
  #advantages {
    padding-top: 20px;
    .main_container {
      .advantages {
        .advantages_title {
          font-size: 28px;
          line-height: 26px;
        }
        .advantages_description {
          font-size: 16px;
          line-height: 18px;
        }
        .advantages_card_wrapper {
          gap: 20px;
          flex-wrap: wrap;
          justify-content: center;
          margin-top: 25px;
          margin-bottom: 25px;
        }
      }
    }
  }
}
@media (max-width: 560px) {
  #advantages {
    .main_container {
      .advantages {
        display: none;
      }
    }
    .advantages_mobile {
      display: block;
      padding: 0 20px;
      margin-bottom: 30px;
      .advantages_card {
        padding: 38px 8px 20px 8px;
        background: rgba(24, 25, 49, 0.37);
        backdrop-filter: blur(27px);
        border-radius: 10px;
        max-width: 100%;
        width: 100%;
        transition: 0.5s all ease;
        height: 100vh;
        max-height: 345px;
        .advantages_img {
          text-align: center;
          margin-bottom: 54px;
        }
        .advantages_card_body {
          h4 {
            font-family: "Sofia Sans", sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 26px;
            text-align: center;
            color: #00fb49;
            margin-bottom: 24px;
          }
          p {
            font-family: "Sofia Sans", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #ffffff;
            max-width: 198px;
            margin: 0 auto;
          }
        }
      }
      .swiper-container {
        overflow: visible;
      }
      .swiper-wrapper {
        align-items: center;
      }
      .swiper-slide {
        transition: 0.5s all ease;
        border-radius: 10px;
      }
      .swiper-slide-active {
        transform: scale(1.05) !important;
        box-shadow: 0px 16px 34px rgba(3, 251, 76, 0.35) !important;
      }
    }
  }
}
</style>
