<template>
    <div class="cookies" :class="{'active': showAcception}">
      <div class="cookies__wrapper">
        <p class="cookies__text">
          This website uses cookies to ensure you get the best experience on our website.  <router-link class="cookies__link" to="/cookies-policy">Cookies policy</router-link>
        </p>
        <div class="cookies__tools">
          <button @click="showAcception = false" class="btn cookis__btn">
            Close
          </button>
          <button @click="acceptCookies" class="btn cookis__btn">
            Accept
          </button>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'CookiesAccept',
  data() {
    return {
      showAcception: false
    }
  },
  methods: {
    acceptCookies() {
      this.setCookie('cookie_accept', 'accepted',  {secure: true, 'max-age': 3600})
      this.showAcception = false
    },
    getCookie(name) {
      let matches = document.cookie.match(
        new RegExp(
          '(?:^|; )' +
            name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
            '=([^;]*)'
        )
      )
      return matches ? decodeURIComponent(matches[1]) : undefined
    },
    setCookie(name, value, options = {}) {
      options = {
        path: '/',
        ...options
      }

      if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString()
      }

      let updatedCookie =
        encodeURIComponent(name) + '=' + encodeURIComponent(value)

      for (let optionKey in options) {
        updatedCookie += '; ' + optionKey
        let optionValue = options[optionKey]
        if (optionValue !== true) {
          updatedCookie += '=' + optionValue
        }
      }

      document.cookie = updatedCookie
    }
  },
  mounted() {
    setTimeout(() => {
      if (!this.getCookie('cookie_accept')) {
        this.showAcception = true
      }
    }, 3000)
    
  }
}
</script>

<style lang="scss" scoped>
.cookies {
  position: fixed;
  bottom: -500px;
  z-index: 9999;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  transition: all 2s;

  &.active{
    bottom: 100px;
  }
}

.cookies__wrapper {
  background: rgba(3, 4, 30, 0.9);
  padding: 25px;
  border-radius: 16px;
  max-width: 90%;
}

.cookies__text {
  color: #00fb49;
  text-align: center;
}

.cookies__tools {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

button{
  margin: 0 10px;
}

.cookies__link{
  font-weight: 500;
  color: #fff;

  &:visited{
    color: #fff;
  }
}
</style>
